import React, { Component } from "react";

import Botao from "../../form/Botao";
import Input from "../../form/Input";

// import * as swal from 'sweetalert';
import Swal from 'sweetalert2';
import goTo from "../../static/goTo";

const routes = require('../../../config/router.js');

class NaoReconhecidoComponent extends Component {
	idUser = "";

  constructor(props) {
    super(props);
    this.state = {
			//TODO remover valor
      numero: "",
      // empresa: JSON.parse(localStorage.getItem('empresa')),
      isAcompanhante: false,
      isPaciente: false,
    };

    // this.setState({
    //   empresa: JSON.parse(localStorage.getItem('empresa')),
    // });
  }

  makeNumber = (numero) => {
    if (this.state.numero.length === 16) {
      return;
    }
    this.setState({
      numero: this.state.numero.concat(numero),
    });
    // console.log(JSON.parse(localStorage.getItem('empresa')))
    // console.log(JSON.parse(localStorage.getItem('empresa')))
  };

  removeNumber = () => {
    this.setState({
      numero: this.state.numero.slice(0, -1),
    });
  };

  linkUserImage = () => {
    const empresa = JSON.parse(localStorage.getItem('empresa'))

    Swal.fire({
      icon: 'info',
      html: 'Estamos buscando suas informações!',
      showConfirmButton: false,
      width: '800px',
      background: '#e3f3ff',
      didOpen: () => {
        Swal.showLoading()
      },
      timer: 7000,
    })

    const url = routes.routes.backend + "beneficiario/listar";

    const carteirinha = this.state.numero;

    // const image = localStorage.getItem('image'); // Nao precisa mais, mas deixarei aqui caso volte algo na rotina

    // console.log(carteirinha)

    const content1 = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        carteirinha,
        'KeyDS': empresa.keyds,
        'endereco_healthmap': empresa.endereco_healthmap,
        'linkv3': empresa.linkv3,
        'loginv3': empresa.loginv3,
        'senhav3': empresa.senhav3
      })
    };

    let user_id = 0;
    let user_name = 0;

    fetch(url, content1)
      .then(res => res.json())
      .then((result) => {
        console.log(result)
        if (result.error === "invalid_token" || result.content[0] === undefined) {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: '<h1>Ocorreu um erro, tente novamente!</h1>',
            background: '#e3f3ff',
            timer: 7000,
          })

          return;
        } else {
					user_id = result.content[0].id;
					this.idUser = user_id
					console.log(this.idUser);
          user_name = result.content[0].nome;
        }
      }).then(() => {

        if (user_id === 0) {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: '<h1>Não existe nenhum cadastro com o número da carteirinha ou CPF informado.</h1>',
            width: '800px',
            background: '#e3f3ff',
            timer: 7000,
          })

          return;

        }

        Swal.fire({
          title: '<h1>Confirmar carteirinha/CPF de</h1>',
          html: '<h1>' + user_name + '</h1>',
          showCancelButton: true,
          width: '750px',
          confirmButtonColor: '#3085d6',
          // cancelButtonColor: '#3085d6',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          imageAlt: 'Custom image',
          background: '#e3f3ff',
        }).then((result) => {
          if (result.isConfirmed) {
						localStorage.setItem('idUser', user_id)
						console.log(user_id);
            // let post_url = localStorage.getItem('CadastroImagem-post_url') ? localStorage.getItem('CadastroImagem-post_url') : null;

            // if (post_url !== null && post_url !== undefined) {
            // Swal.fire({
            //   icon: 'success',
            //   html: '<h1>Confirmado, redirecionando...</h1>',
            //   showConfirmButton: false,
            //   width: '800px',
            //   background: '#e3f3ff',
            //   timer: 3000
            // })
            // setTimeout(1000)
            // this.reconheceSemImagem(user_id, post_url);
            // return;
            // } else {
            this.reconheceSemImagem(user_id, localStorage.getItem('CadastroImagem-post_url'));
            // }
          }
          // else {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Confirmado, redirecionando...',
          //     showConfirmButton: false,
          //     width: '800px',
          //     background: '#e3f3ff',
          //     timer: 3000
          //   }).then(() => { this.reconheceSemImagem(user_id, null); })
          // }
        })
      })

  }

  goToHome = () => {
    // window.location.href = routes.routes.frontend + 'home';
    goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
    localStorage.clear();
    console.log(localStorage.getItem('empresa'))
  }

  recheckImage(image) {

    if (image === undefined || image === null) {
      console.log('imagem undefined ou null, nao tem como ver se tem consulta, mandando para /home dnv')
      // window.location.href = routes.routes.frontend + 'home';
      this.goToHome();
      return;
    }

    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ image })
    };

    fetch(routes.routes.backend + 'imageapi/verify_img', json)
      .then(res => res.json())
      .then((result) => {
				console.log(result);
        if (result.data.length === 0) {

          // se entrou aqui é pq nao tem nenhuma pessoa na imagem, gambiarra pra atualizar a pagina e tentiar dnv
          window.location.reload(false);
          return;

        } else if (result.data[0].message === "User found with following info. look for user_id") {

          console.log(result.data[0])

          const id = result.data[0].user.user_id; // 101 - 309 - 812 - 1415 -> testes
          // const KeyDS = routes.routes.keyDS;

          const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));

          const json = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id,
              'username': jsonEmpresa.loginv3,
              'password': jsonEmpresa.senhav3,
              'linkv3': jsonEmpresa.linkv3,
              'KeyDS': jsonEmpresa.keyds,
              'endereco_healthmap': jsonEmpresa.endereco_healthmap,
            })
          }

          console.log(json)

          let beneficiario;

          fetch(routes.routes.backend + 'consulta/show_data', json)
            .then(res => res.json())
            .then((result) => {
              beneficiario = result;
            }, (error) => {
              console.log(error)
            }).then(() => {
              if (beneficiario === undefined || beneficiario.type === 'invalid-json') {
                console.log('Não existe cadastro com o id dessa pessoinha')
                return;
              }

              console.log(beneficiario)

              // verifica se tem consulta com status ESPERA hoje 

              let consultasHoje = [];

              var status = 'ESPERA';

              const jsonEmEspera = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  status,
                  id,
                  'username': jsonEmpresa.loginv3,
                  'password': jsonEmpresa.senhav3,
                  'KeyDS': jsonEmpresa.keyds,
                  'linkv3': jsonEmpresa.linkv3,
                  'endereco_healthmap': jsonEmpresa.endereco_healthmap,
                })
              }

              fetch(routes.routes.backend + 'consulta/show', jsonEmEspera)
                .then(res => res.json())
                .then((consultas) => {
                  console.log(consultas)

                  if (consultas.length !== 0) {
                    console.log('O beneficiario ' + beneficiario.nome + ' já confirmou uma consulta hoje')
                    localStorage.setItem('mensagem', 'Você já efetuou a confirmação da sua consulta. Por favor, aguarde.')
                    // window.location.href = routes.routes.frontend + 'reconhecido';
                    goTo('reconhecido', JSON.parse(localStorage.getItem('empresa')).barramento);
                    return;
                  }

                  status = 'CONFIRMADO';

                  const jsonConfirmado = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      status,
                      id,
                      'username': jsonEmpresa.loginv3,
                      'password': jsonEmpresa.senhav3,
                      'KeyDS': jsonEmpresa.keyds,
                      'linkv3': jsonEmpresa.linkv3,
                      'endereco_healthmap': jsonEmpresa.endereco_healthmap,
                    })
                  }

                  fetch(routes.routes.backend + 'consulta/show', jsonConfirmado)
                    .then(res => res.json())
                    .then((consultas) => {
                      console.log(consultas)

                      if (consultas.length === 0) {

                        status = 'AGENDADO';

                        const jsonAgendado = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            status,
                            id,
                            'username': jsonEmpresa.loginv3,
                            'password': jsonEmpresa.senhav3,
                            'KeyDS': jsonEmpresa.keyds,
                            'linkv3': jsonEmpresa.linkv3,
                            'endereco_healthmap': jsonEmpresa.endereco_healthmap,
                          })
                        }

                        fetch(routes.routes.backend + 'consulta/show', jsonAgendado)
                          .then(res => res.json())
                          .then((consultas) => {
                            if (consultas.length === 0) {
                              console.log('O beneficiario ' + beneficiario.nome + ' não possui nenhuma consulta com status = CONFIRMADO ou AGENDADO')
                              localStorage.setItem('mensagem', 'Você não tem nenhuma consulta agendada.')
                              // window.location.href = routes.routes.frontend + 'reconhecido';
                              goTo('reconhecido', JSON.parse(localStorage.getItem('empresa')).barramento);
                              return;
                            }
                            this.verificarConsultasComImagem(consultas, consultasHoje, beneficiario, result)
                          }, (error) => {
                            console.log(error)
                            console.log(json);
                            Swal.fire({
                              icon: 'info',
                              title: 'Desculpe',
                              html: '<h4>Houve um problema ao buscar as consultas agendadas. Tente novamente, por favor</h4>',
                              showConfirmButton: false,
                              width: '800px',
                              background: '#e3f3ff',
                              timer: 4000
                            })
                          })
                      } else {
                        this.verificarConsultasComImagem(consultas, consultasHoje, beneficiario, result)
                      }
                    }, (error) => {
                      console.log(error)
                      Swal.fire({
                        icon: 'info',
                        title: 'Desculpe',
                        html: '<h4>Houve um problema ao buscar pela sua consulta. Tente novamente, por favor</h4>',
                        showConfirmButton: false,
                        width: '800px',
                        background: '#e3f3ff',
                        timer: 4000
                      }).then(() => {
                        // window.location.href = routes.routes.frontend + 'home'; 
                        this.goToHome();
                      })
                    });
                }, (error) => {
                  console.log(error)
                  Swal.fire({
                    icon: 'info',
                    title: 'Desculpe',
                    html: '<h4>Houve um problema ao buscar pela sua consulta. Tente novamente, por favor</h4>',
                    showConfirmButton: false,
                    width: '800px',
                    background: '#e3f3ff',
                    timer: 4000
                  }).then(() => {
                    // window.location.href = routes.routes.frontend + 'home'; 
                    this.goToHome();
                  })
                });

            })

        } else if (result.data[0].message === "User with this face does not exists, however profile is created. please send post request to post_url with user_id"
          || result.data[0].message === "user_id has not been set, please send post request to post_url with user_id") {

          console.log(result.data[0].message);
          let post_url = localStorage.getItem('CadastroImagem-post_url')
          let empresa = JSON.parse(localStorage.getItem('empresa'));
          localStorage.clear();
          localStorage.setItem('CadastroImagem-post_url', post_url ? post_url : result.data[0].post_url);
          localStorage.setItem('empresa', JSON.stringify(empresa))
					localStorage.setItem('idUser', this.idUser)
          // window.location.href = routes.routes.frontend + 'naoreconhecido';
          goTo('naoreconhecido', JSON.parse(localStorage.getItem('empresa')).barramento);

        } else if (result.data[0].message === "resolve the following errors") {

          // deu algum erro quando mandou a imagem do banco
          console.log(result.data[0].message)
          window.location.reload(false);

        }
      },
        (error) => {
          console.log(error);
          window.location.reload(false);
        });

  }

  reconheceSemImagem(userid, post_url) {

    Swal.fire({
      icon: 'info',
      html: 'Estamos carregando suas informações!',
      showConfirmButton: false,
      width: '800px',
      background: '#e3f3ff',
      didOpen: () => {
        Swal.showLoading()
      },
      timer: 15000,
    })
    const id = userid;
    const KeyDS = routes.routes.keyDS;

    const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));

    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id,
        'username': jsonEmpresa.loginv3,
        'password': jsonEmpresa.senhav3,
        'linkv3': jsonEmpresa.linkv3,
        'endereco_healthmap': jsonEmpresa.endereco_healthmap,
        'KeyDS': jsonEmpresa.keyds,
      })
    }

    let beneficiario;

    fetch(routes.routes.backend + 'consulta/show_data', json)
      .then(res => res.json())
      .then((result) => {
        console.log(result)
        beneficiario = result;
      }, (error) => {
        console.log(error)
      }).then(() => {
        if (beneficiario === undefined || beneficiario.type === 'invalid-json') {
          console.log('Não existe cadastro com o id dessa pessoinha')
          Swal.fire({
            icon: 'info',
            title: 'Não localizamos sua consulta.',
            html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Entendi',
            width: '800px',
            background: '#e3f3ff',
            timer: 7000,
            timerProgressBar: true,
          }).then(() => {
            // window.location.href = routes.routes.frontend + 'home'; 
            this.goToHome();
          })
          return;
        }

        console.log(beneficiario)

        let consultasHoje = [];

        const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));

        // console.log(Authorization);

        const jsonEmEspera = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // status,
            id,
            'username': jsonEmpresa.loginv3,
            'password': jsonEmpresa.senhav3,
            'KeyDS': jsonEmpresa.keyds,
            'linkv3': jsonEmpresa.linkv3,
            'endereco_healthmap': jsonEmpresa.endereco_healthmap,
          })
        }

        fetch(routes.routes.backend + 'consulta/show_all', jsonEmEspera)
          .then(res => res.json())
          .then((consultas) => {
            console.log(consultas)
            consultas.reverse();

            let date = new Date();
            // console.log(date.getHours() + ':' + date.getMinutes())

            if (consultas.length === 0) {
              if (sessionStorage.getItem('totem')) {
                Swal.fire({
                  icon: 'info',
                  title: 'Não localizamos sua consulta.',
                  html: '<h1>Deseja marcar uma consulta?​</h1>',
                  showConfirmButton: true,
                  showCancelButton: true,
                  width: '800px',
                  background: '#e3f3ff',
                  timer: 7000,
                  confirmButtonText: `Confirmar`,
                  cancelButtonText: `Cancelar`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.fazerAgendamento(beneficiario);
                  } else {
                    goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
                  }
                })
              } else {
                Swal.fire({
                  icon: 'info',
                  title: 'Não localizamos sua consulta.',
                  html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                  showConfirmButton: false,
                  width: '800px',
                  background: '#e3f3ff',
                  timer: 7000
                }).then(() => {
                  // window.location.href = routes.routes.frontend + 'home'; 
                  // window.location.href = routes.routes.frontend + 'home'; 
                  // window.location.href = routes.routes.frontend + 'home'; 
                })
                localStorage.clear();
                goTo('home', jsonEmpresa.barramento);
              }
            } else {
              this.verificaAgendamentos(consultas, beneficiario)
              // consultas.map(consulta => this.verificaAgendamentos(consulta, beneficiario));
            }
          })
      })
  }

  verificarConsultasComImagem(consultas, consultasHoje, beneficiario, result) {
    let hoje = this.formatDate(new Date().toDateString());

    for (let i = 0; i < consultas.length; i++) {
      if (consultas[i].data === hoje) {
        consultasHoje.push(consultas[i])
      }
    }

    if (consultasHoje.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Não localizamos seu agendamento.',
        html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Entendi',
        width: '800px',
        background: '#e3f3ff',
        timer: 7000,
        timerProgressBar: true,
      }).then(() => {
        // window.location.href = routes.routes.frontend + 'home'; 
        this.goToHome();
      })

    } else {

      console.log(
        'Foi encontrada uma ou mais consulta(s) com status = CONFIRMADO no dia de hoje, mandando então a consulta mais próxima do horário atual e ' +
        'mudando o status dela para EM ESPERA'
      );

      let proxConsulta = consultasHoje[0];

      for (let i = 1; i < consultasHoje.length; i++) {
        if (consultasHoje[i].data < proxConsulta) {
          proxConsulta = consultasHoje[i];
        }
      }

      const Authorization2 = 'Basic ' + Buffer.from('hmwsclient:healthm@pws.client').toString('base64');
      const idConsulta = proxConsulta.idConsulta;

      console.log(beneficiario.nome)
      // console.log(beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro')
      console.log(proxConsulta.nomeProfissional)
      console.log(this.unformatDate(proxConsulta.data))
      console.log(proxConsulta.horaInicio + ' a ' + proxConsulta.horaFim)
      // console.log(result.data[0].user.image)

      let isAcompanhante = localStorage.getItem('isAcompanhante');
      let postURL = localStorage.getItem('CadastroImagem-post_url');
      let mostrar_termo = localStorage.getItem('mostrar_termo');
      let empresa = JSON.parse(localStorage.getItem('empresa'));
      localStorage.clear();
      localStorage.setItem('empresa', JSON.stringify(empresa))
			localStorage.setItem('idUser', this.idUser)
      if (proxConsulta === undefined) {
        localStorage.setItem('mensagem', 'Você não tem nenhuma consulta agendada.');
        // window.location.href = routes.routes.frontend + 'home';
        this.goToHome();
        return;
      }
      localStorage.setItem('nome', beneficiario.nome);
      localStorage.setItem('id_empresa', empresa.id);
      localStorage.setItem('documento', beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro');
      localStorage.setItem('medico', proxConsulta.nomeProfissional);
      localStorage.setItem('dataConsulta', this.unformatDate(proxConsulta.data));
      localStorage.setItem('horario', proxConsulta.horaInicio + ' a ' + proxConsulta.horaFim);
      localStorage.setItem('Authorization', Authorization2);
      localStorage.setItem('idConsulta', idConsulta);
      localStorage.setItem('isAcompanhante', isAcompanhante);
      localStorage.setItem('CadastroImagem-post_url', postURL);
      localStorage.setItem('CadastroImagem-userid', beneficiario.id);
      localStorage.setItem('mostrar_termo', mostrar_termo);
      // localStorage.setItem('mensagem', 'Consulta confirmada! Por favor, aguarde ulta confirmada! Por favor, aguarde ser chamado.');
      // localStorage.setItem('foto', result.data[0].user.image)
      console.log(localStorage.getItem('CadastroImagem-userid'));
      // localStorage.setItem('foto', result.data[0].user.image)
      if (this.state.isPaciente) {
        // window.location.href = routes.routes.frontend + 'attfoto';
        goTo('attfoto', JSON.parse(localStorage.getItem('empresa')).barramento);
      } else {
        // window.location.reconhecido = routes.routes.frontend + 'reconhecido';
        goTo('reconhecido', JSON.parse(localStorage.getItem('empresa')).barramento);
      }
    }
  }

  fazerAgendamento(beneficiario) {
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    localStorage.setItem('CadastroImagem-userid', beneficiario.id)

    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'id_usuario': beneficiario.id,
        'loginv3': empresa.loginv3,
        'senhav3': empresa.senhav3,
        'KeyDS': empresa.keyds,
        'linkv3': empresa.linkv3,
      })
    };
    console.log(JSON.parse(json.body))
    fetch(routes.routes.backend + 'profissionais/listar', json)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        if (result.profissionaisReferencia || result.profissionaisReferencia) {
          console.log(result.profissionaisReferencia)
          console.log(result.medicosGestores)
          localStorage.setItem('medicosGestores', JSON.stringify(result.medicosGestores))
          localStorage.setItem('profissionaisReferencia', JSON.stringify(result.profissionaisReferencia))
          goTo('medico-agenda', empresa.barramento)
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Não seu profissional de referência.',
            html: '<h1>Dirija-se à recepção para mais informações.​</h1>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000
          }).then(() => {
            goTo('home', empresa.barramento)
          })
        }
      })
  }

  verificaAgendamentos(consultas, beneficiario) {
    console.log(consultas)
    for (var i = 0; i < consultas.length; i++) {
      var consulta = consultas[i];
      let date = new Date();
      // console.log(date.getHours() + ':' + date.getMinutes())

      const dateConsulta = new Date(consulta.data + ' ' + consulta.horaFim);
      // console.log(dateConsulta.getHours() + ':' + dateConsulta.getMinutes())

      let dateConsultaConsideraAtraso = new Date(consulta.data + ' ' + consulta.horaFim);
      dateConsultaConsideraAtraso.setHours(dateConsulta.getHours() + 2);
      // console.log(dateConsultaConsideraAtraso.getHours() + ':' + dateConsultaConsideraAtraso.getMinutes())

      if (dateConsulta.getDay() == date.getDay()) {
        if (consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
          // console.log(consulta)
          console.log('CONFIRMADO || AGENDADO')
          this.validarConsulta(consulta, beneficiario);
          break;
        } else if (consulta.status === 'ESPERA') {
          // console.log(consulta)
					console.log(i);
          console.log('ESPERA: %s', i)


          Swal.fire({
            icon: 'info',
            title: 'Consulta já confirmada. Por favor aguarde',
            // html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000
          })
            .then(() => {
              // window.location.href = routes.routes.frontend + 'home';
              this.goToHome();
            })

          break;
        } else {
          if (i == (consultas.length - 1)) {
            if (sessionStorage.getItem('totem')) {
              Swal.fire({
                icon: 'info',
                title: 'Não localizamos sua consulta.',
                html: '<h1>Deseja marcar uma consulta?​</h1>',
                showConfirmButton: true,
                showCancelButton: true,
                width: '800px',
                background: '#e3f3ff',
                timer: 7000,
                confirmButtonText: `Confirmar`,
                cancelButtonText: `Cancelar`,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.fazerAgendamento(beneficiario);
                } else {
                  goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
                }
              })
            } else {
              Swal.fire({
                icon: 'info',
                title: 'Não encontramos sua consulta',
                html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                showConfirmButton: false,
                width: '800px',
                background: '#e3f3ff',
                timer: 7000
              })
                .then(() => {
                  // window.location.href = routes.routes.frontend + 'home'; 
                  // window.location.href = routes.routes.frontend + 'home'; 
                  // window.location.href = routes.routes.frontend + 'home'; 
                  this.goToHome();
                })
            }
          }
        }
      } else if (dateConsultaConsideraAtraso.getTime() > date.getTime()
        && dateConsultaConsideraAtraso.getDay() == date.getDay()) {
        if (consulta.status === 'ATRASADO' || consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
          console.log(consulta)
          console.log('ATRASADO')
          this.validarConsulta(consulta, beneficiario);
          break;
        }
      } else if (i == (consultas.length - 1)) {
        // console.log(consulta)
        // console.log(dateConsulta.getDay() != date.getDay())
        // console.log(i == (consultas.length - 1))
        if (sessionStorage.getItem('totem')) {
          Swal.fire({
            icon: 'info',
            title: 'Não localizamos sua consulta.',
            html: '<h1>Deseja marcar uma consulta?​</h1>',
            showConfirmButton: true,
            showCancelButton: true,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.fazerAgendamento(beneficiario);
            } else {
              goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
            }
          })
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Não encontramos sua consulta',
            html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000
          })
            .then(() => {
              // window.location.href = routes.routes.frontend + 'home'; 
              this.goToHome();
            })
        }
      }
    }
  }

  validarConsulta(consulta, beneficiario) {
    const Authorization2 = 'Basic ' + Buffer.from('hmwsclient:healthm@pws.client').toString('base64');
    const idConsulta = consulta.idConsulta;

    let isAcompanhante = localStorage.getItem('isAcompanhante');
    let mostrar_termo = localStorage.getItem('mostrar_termo');
    let empresa = JSON.parse(localStorage.getItem('empresa'));
    localStorage.clear();
    localStorage.setItem('empresa', JSON.stringify(empresa))
		localStorage.setItem('idUser', this.idUser)

    console.log(beneficiario.nome);
    // console.log(beneficiario.documentos[0].documento);
    console.log(consulta.nomeProfissional);
    console.log(this.unformatDate(consulta.data));
    console.log(consulta.horaInicio + ' a ' + consulta.horaFim);
    // console.log(result.data[0].user.image);

    localStorage.setItem('nome', beneficiario.nome);
    localStorage.setItem('id_empresa', empresa.id);
    localStorage.setItem('CadastroImagem-userid', beneficiario.id);
    localStorage.setItem('documento', beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro');
    localStorage.setItem('medico', consulta.nomeProfissional);
    localStorage.setItem('dataConsulta', this.unformatDate(consulta.data));
    localStorage.setItem('horario', consulta.horaInicio + ' a ' + consulta.horaFim);
    // localStorage.setItem('user_id', beneficiario.)
    //localStorage.setItem('mensagem', 'Consulta confirmada! Por favor, aguarde ser chamado.');
    // localStorage.setItem('foto', image);
    localStorage.setItem('Authorization', Authorization2);
    localStorage.setItem('idConsulta', idConsulta);
    localStorage.setItem('mostrar_termo', mostrar_termo);
    console.log(localStorage.getItem('CadastroImagem-userid'))
    console.log('validarConsulta Clear()')
    if (this.state.isPaciente) {
      // window.location.href = routes.routes.frontend + 'attfoto';
      goTo('attfoto', JSON.parse(localStorage.getItem('empresa')).barramento);
    } else {
      // window.location.href = routes.routes.frontend + 'reconhecido';
      goTo('reconhecido', JSON.parse(localStorage.getItem('empresa')).barramento);
    }
    // }
  }

  unformatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  unformatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  setPacienteOuAcompanhante(e) {
    if (e.target.value === 'isPaciente') {// so por garantia
      this.setState({
        isPaciente: true,
      });
    } else {
      this.setState({
        isAcompanhante: true,
      });
    }
    // window.location.reload();
  }

  render() {

    let mensagem = 'DIGITE SUA CARTEIRINHA​ OU CPF';
    if (!this.state.isPaciente) {
      mensagem = 'DIGITE A CARTEIRINHA​ OU CPF DO PACIENTE';
    }
    setTimeout(() => {
      goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
    }, 120000);

    if (!(this.state.isPaciente || this.state.isAcompanhante)) {
      return (
        <div id="fullpage" className="fullpage-default">
          <div className="section animated-row" data-section="slide02">
            <div className="section-inner">
              {/* <h1 className="welcome-title"> Bem-Vindo! </h1> */}
              <div className="row justify-content-center">
                <div className="col-md-7 wide-col-laptop">
                  <div className="contact-section">
                    <div className="row justify-content-center">
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <button className="btn" type="submit"
                          // style={{ fontSize: 30}} 
                          style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
                          value='isPaciente'
                          onClick={(e) => this.setPacienteOuAcompanhante(e)}>Meu Atendimento</button>
                      </div>
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <button className="btn btn-secondary btn-lg" type="submit"
                          // style={{ fontSize: 30}}
                          style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
                          value='isAcompanhante'
                          onClick={(e) => this.setPacienteOuAcompanhante(e)}>Acompanhante</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div id="fullpage" className="fullpage-default">
          <div className="section animated-row" data-section="slide02">
            <div className="section-inner">
              <div className="about-section">
                <div className="row justify-content-center">
                  <div className="col-lg-10 wide-col-laptop">
                    <div className="row">
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <div style={{ paddingTop: 60 }} className="contact-box">
                          <div className="contact-row">
                            <span style={{ textAlign: "center", fontSize: 30 }}>
                              {mensagem}
                            </span>
                          </div>
                          {/* <div id="ajax-contact" method="post" action="#"> */}
                          <div className="input-field">
                            <Input number={this.state.numero} />
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {/* <div className="col-md-6"> */}
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <div className="row" style={{ flexWrap: "initial" }}>
                          <div>
                            <Botao number="7" click={this.makeNumber.bind(this, "7")}
                            />
                          </div>
                          <div>
                            <Botao number="8" click={this.makeNumber.bind(this, "8")}
                            />
                          </div>
                          <div>
                            <Botao number="9" click={this.makeNumber.bind(this, "9")}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ flexWrap: "initial" }}>
                          <div>
                            <Botao number="4" click={this.makeNumber.bind(this, "4")}
                            />
                          </div>
                          <div>
                            <Botao number="5" click={this.makeNumber.bind(this, "5")}
                            />
                          </div>
                          <div>
                            <Botao number="6" click={this.makeNumber.bind(this, "6")}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ flexWrap: "initial" }}>
                          <div>
                            <Botao number="1" click={this.makeNumber.bind(this, "1")}
                            />
                          </div>
                          <div>
                            <Botao number="2" click={this.makeNumber.bind(this, "2")}
                            />
                          </div>
                          <div>
                            <Botao number="3" click={this.makeNumber.bind(this, "3")}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ flexWrap: "initial" }}>
                          <div>
                            <Botao number="CORRIGE" click={this.removeNumber} thisFontSize='80' thisBackgroundColor='#e5e754' thisBorderColor='#e5e754' />
                          </div>
                          <div>
                            <Botao number="0" click={this.makeNumber.bind(this, "0")}
                            />
                          </div>
                          <div>
                            <Botao number="OK" click={this.linkUserImage} thisFontSize='60' thisBackgroundColor='#55cd7e' thisBorderColor='#55cd7e' />
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NaoReconhecidoComponent;
