import React, { useState, Component } from "react";
import goTo from "../static/goTo.jsx";
import Swal from 'sweetalert2';

const routes = require('../../config/router.js');

class ConsultaComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agendaConsultaLista: JSON.parse(localStorage.getItem('agendaConsultaLista')),
      agendaConsultaSelectionada: localStorage.getItem('agendaConsultaSelecionada'),
      diaSelecionado: JSON.parse(localStorage.getItem('agendaConsultaLista'))[0].agenda[0].data,
      horasDisponiveis: JSON.parse(localStorage.getItem('agendaConsultaLista'))[0].agenda[0],
      horaSelecionada: JSON.parse(localStorage.getItem('agendaConsultaLista'))[0].agenda[0].horarios[0],
    };
  }

  setDia(indexDia) {
    this.setState({ 'diaSelecionado': indexDia.value })
    this.setState({ 'horasDisponiveis': this.state.agendaConsultaLista[0].agenda[indexDia.selectedIndex] })
  }

  setBloco(indexBloco) {
    this.setState({ 'horaSelecionada': this.state.horasDisponiveis.horarios[indexBloco.selectedIndex] })
  }

  agendar() {
    Swal.fire({
      icon: 'info',
      title: 'Por favor, confirme a data agendada.',
      html: '<h1>' + this.state.diaSelecionado + ' ' + this.state.horaSelecionada.horaInicio + '</h1>',
      showConfirmButton: true,
      showCancelButton: true,
      width: '800px',
      background: '#e3f3ff',
      timer: 15000,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval;
        Swal.fire({
          icon: 'info',
          html: 'Agendando, por favor aguarde.',
          timer: 10000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
              const content = Swal.getHtmlContainer()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = Swal.getTimerLeft()
                }
              }
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
        this.realizaAgendamento();
      }
    })
  }

  realizaAgendamento() {
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    let json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        info: {
          'id_usuario': localStorage.getItem('CadastroImagem-userid'),
          'loginv3': empresa.loginv3,
          'senhav3': empresa.senhav3,
          'KeyDS': empresa.keyds,
          'linkv3': empresa.linkv3,
        },
        'data': this.state.diaSelecionado,
        'horaInicio': this.state.horaSelecionada.horaInicio,
        'horaFim': this.state.horaSelecionada.horaFim,
        'idLocal': this.state.horaSelecionada.idLocal,
        'idTipoConsulta': this.state.horaSelecionada.idTipoConsulta,
        'idUnidade': this.state.horaSelecionada.idUnidade,
        'local': this.state.horaSelecionada.local,
        'nomeUnidade': this.state.horaSelecionada.nomeUnidade,
        'permiteOnline': this.state.horaSelecionada.permiteOnline,
        'idCliente': localStorage.getItem('CadastroImagem-userid'),
        'idProfissional': JSON.parse(localStorage.getItem('medicoSelecionado')).id,
      })
    };


    fetch(routes.routes.backend + 'agenda/agendar', json)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        Swal.fire({
          icon: 'success',
          title: 'Agenda realizado com sucesso.',
          // html: '<h1>Dirija-se à recepção para mais informações.​</h1>',
          showConfirmButton: false,
          width: '800px',
          background: '#e3f3ff',
          timer: 7000
        }).then(() => {
           goTo('home', empresa.barramento)
        })
      })
  }

  render() {
    setTimeout(() => {
      goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
    }, 150000);
    return (
      <div className="section">
        {/* <div className="section animated-row"> */}
        <div className="section">
          <div className="section-inner">
            <div className="welcome-box">
              <h1 style={{ fontSize: 80 }}>
                Agendamentos disponíveis
              </h1>
              <div className="section-inner">
                <div className="row justify-content-center">
                  <div className="col-md-7 wide-col-laptop">
                    <div className="contact-section">
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <h2 style={{ fontSize: 70, textAlign: 'left' }}>
                            Data:
                        </h2>
                        </div>
                        <div className="col-md-3">
                          {this.state.agendaConsultaLista &&
                            this.state.agendaConsultaLista.map((consultasDisponiveis) =>
                              <select onChange={(input) => this.setDia(input.target)} className='form-control custom-select' style={{ fontSize: 50, height: 80 }}>
                                {consultasDisponiveis.agenda.map((item, index) => (
                                  <option key={'diazinhos' + index} value={item.data}
                                    className='option' style={{ fontSize: 50, background: '#0099fc' }}>
                                    {item.data}
                                  </option>
                                ))}
                              </select>
                            )}
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <h2 style={{ fontSize: 70, textAlign: 'left' }}>
                            Horario:
                        </h2>
                        </div>
                        <div className="col-md-3">
                          <select onChange={(input) => this.setBloco(input.target)} className='form-control custom-select' style={{ fontSize: 50, height: 80 }}>
                            {
                              this.state.horasDisponiveis && this.state.horasDisponiveis.horarios &&
                              this.state.horasDisponiveis.horarios.length > 0 &&
                              this.state.horasDisponiveis.horarios.map((item, index) => (
                                // console.log(item)
                                <option key={'horinha' + index} value={item} className='option' style={{ fontSize: 50, background: '#0099fc' }}>
                                  {item.horaInicio}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn" type="submit"
                  style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
                  onClick={e => this.agendar()}>Realizar Agendamento​</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ConsultaComponent;