import React, { useState, Component } from "react";
import useInterval from '@use-it/interval';
import goTo from "../static/goTo.jsx";

const routes = require('../../config/router.js');

class CapsulaOcupadoComponent extends Component {
  render() {
    return (
      <div id="fullpage" className="fullpage-default">
        <div className="section animated-row" data-section="slide02">
          <div className="section-inner">
            <div className="about-section">
              <div className="container-sm">
                <div className="row justify-content-center">
                  <div className="col-md-6 animate" data-animate="fadeInUp">
                    <SetMensagem />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const SetMensagem = () => {
  const [mensagem, setMensagem] = useState('Carregando');
  const [status, setStatus] = useState(undefined);
  useInterval(
    () => {
      const totemStatus = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'id_empresa': JSON.parse(localStorage.getItem('empresa')).id,
          'id_totem_cadastro': JSON.parse(sessionStorage.getItem('totem')).id
        })
      }

      fetch(routes.routes.backend + 'totem_status_search', totemStatus)
        .then(res => res.json())
        .then(res => {
          if (status != res.id_status) {
            setStatus(res.id_status);
          }
          console.log(res)
          console.log(mensagem)
          if (res.id_status) {
            switch (res.id_status) {
              case '1':
                goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
              case '2':
                setMensagem(res.mensagem_abrir_totem ? res.mensagem_abrir_totem : 'Cápsula Aberta');
                break;
              case '3':
                setMensagem(res.mensagem_em_uso_totem ? res.mensagem_em_uso_totem : 'Cápsula em uso');
                break;
              case '4':
                setMensagem(res.mensagem_higienizando_totem ? res.mensagem_higienizando_totem : 'Cápsula sendo higienizada');
                break;
              default:
                // setMensagem('Cápsula em uso. Por favor, aguarde');
            }
          }
        })
    },
    5000
  );

  return (
    <h2 style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
    >{mensagem}​</h2>
  );
}

export default CapsulaOcupadoComponent;