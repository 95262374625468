// import { useEffect, useState } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

// import video from '../../assets/images/video.mp4';
import Videos from './VideoComponent'
import HeaderComponent from './HeaderComponent';

import routes from './routes'

const Rotas = (props) => {
  const location = useLocation();

  return (
    <div id="video">

      <HeaderComponent />

      <Videos />

      <div id="fullpage" className="fullpage-default">
        <Switch location={location} key={location.pathname}>
          <Redirect exact from="/" to="/home?emp=teste" />
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                component={(props => {
                  return (
                    <route.component {...props}/>
                  );
                })}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default Rotas;