import { Component } from 'react';

import RotasComponent from './paginas/static/RotasComponent';
// import { BrowserRouter } from 'react-router-dom';

// import HomeComponent from './paginas/home/HomeComponent';
// import ReconhecidoComponent from './paginas/reconhecimento/reconhecido/ReconhecidoComponent';
// import NaoReconhecidoComponent from './paginas/reconhecimento/naoreconhecido/NaoReconhecidoComponent';
// import RetornoComponent from './paginas/retorno/RetornoComponent';
import { BrowserRouter } from 'react-router-dom';

const routes = require('./config/router.js');
class App extends Component {
  // const App = {
  constructor() {
    super();
    this.start();
    this.state = {
      empresas: []
    };
  }

  async start() {
    const search = window.location.href;
    let match = search.match(/emp=(.*)&/);
    if(match){
      let emp = match?.[1];
      await this.mountEmpresa(emp);
      await this.findTotem(emp);
    } else {
      match = search.match(/emp=(.*)/);
      let emp = match?.[1];
      await this.mountEmpresa(emp);
    }
  }

  mountEmpresa(emp) {
    const jsonEmEspera = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    fetch(routes.routes.backend + 'empresa', jsonEmEspera)
      .then(res => res.json())
      .then(res => {
        const empresaAux = res.filter(obj => obj.barramento === emp)
        // console.log(empresaAux)
        localStorage.setItem('empresa', JSON.stringify(empresaAux[0]));
        sessionStorage.setItem('empresas', JSON.stringify(empresaAux));
      })
  }

  findTotem(emp) {
    const search = window.location.href;
    const match = search.match(/totem=(.*)/);
    const nome = match?.[1];
    const totem = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        empresa_barramento: emp,
        nome
      })
    }

    fetch(routes.routes.backend + 'totem_cadastro_search', totem)
      .then(res => res.json())
      .then(res => {
        console.log(res[0])
        sessionStorage.setItem('totem', JSON.stringify(res[0]));
      })
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <RotasComponent />
      </BrowserRouter>
    );
  }
}

export default App;
