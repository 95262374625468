import React from "react";
import { Redirect } from "react-router-dom";
import goTo from "../../static/goTo";
import { useEffect } from "react";
import Swal from 'sweetalert2';
const routes = require('../../../config/router.js');

const Questionario = props => {

  const [questao, setQuestao] = React.useState(props.questionario[0]);
  useEffect(() => { if (questao.dispara_acao == 1) { setProximaPergunta(undefined, 0, undefined); } }, [])

  function setProximaPergunta(resposta, index, prox_apontamento) {
    let apontamento
    console.log(index + "  " + apontamento)
    if (prox_apontamento>=0)
      apontamento = prox_apontamento
    else
      if (index)
        apontamento = questao.apontamento_respostas[index]
      else if (questao.dispara_acao == 0)
        apontamento = questao.apontamento_respostas[0]

    let proximaQuestao;
    if (apontamento != -1) {
      if (apontamento > 0) {
        proximaQuestao = props.questionario.find(questao => questao.sequencia == apontamento)
      } else if (apontamento == 0) {
        const empresa = JSON.parse(localStorage.getItem('empresa'));
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Dirija-se a Recepção',
          text: questao.mensagem[index],
          showConfirmButton: false,
          timer: 20000,
        }).then(() =>
          goTo('home', empresa.barramento)
        )
      } else {
        proximaQuestao = props.questionario.find(questao => (questao.sequencia + 1) == (questao.sequencia + 1))
      }
    }

    console.log(proximaQuestao)
    if (apontamento != 0) {
      if (!proximaQuestao) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: '<h1>Importante!</h1>',
          width: 1000,
          html: '<h2>Ao sair de sua consulta, evite permanecer na clínica. Assim, você evitará riscos desnecessários à sua saúde e dos demais pacientes.​</h2>',
          showConfirmButton: true,
          confirmButtonText: '<h1>Estou ciente</h1>',
          timer: 20000,
        }).then(() =>
          props.capturar()
        )
      } else {
        if (proximaQuestao)
          setQuestao(proximaQuestao)
        if (proximaQuestao.dispara_acao) {
          //dispara acao
          if (proximaQuestao.dispara_acao == 1) {
            //faz condicao
            abrirPortaArduino(proximaQuestao);
          }
        }
      }
    }
  }


  async function abrirPortaArduino(proximaQuestao) {
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: 'AGUARDE',
      text: 'Estamos avaliando sua temperatura corporal.',
      didOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
      timer: 2000,
    })
    if ("serial" in navigator) {
      var port;
      var txtTemperaturas = "";
      const ports = await navigator.serial.getPorts();
      if (ports.length <= 0) {
        port = await navigator.serial.requestPort().catch((e) => console.log('Porta não selecionada'));
      } else {
        port = ports[0];
      }
      if (!port) {
        props.capturar();
      }
      try {
        if (!port.readable) {
          await port.open({ baudRate: 9600 });
        }
        const reader = port.readable.getReader();
        var reads = 0;
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            reader.releaseLock();
            break;
          }
          txtTemperaturas += new TextDecoder().decode(value);
          reads++;
          if (reads >= 10) {
            reader.releaseLock();
            break;
          }
        }
        var temperaturas = txtTemperaturas.replaceAll("\r", "").replaceAll("\n", "").replaceAll(" ", "").split(",");
        var temperatura = 0;
        var valoresValidos = 0;
        for (var i = 0; i < temperaturas.length; i++) {
          if (!isNaN(parseFloat(temperaturas[i]))) {
            temperatura += parseFloat(temperaturas[i]);
            valoresValidos++;
          }
        }
        temperatura /= valoresValidos;
        port.close();
        validaTemperatura(temperatura, proximaQuestao);

      } catch (e) {
        console.log(e.message);
        props.capturar();
      }
    }
  }

  async function validaTemperatura(temperatura, proximaQuestao) {
    temperatura += 4;
    console.log('Temperatura Lida: ' + temperatura);
    var nenhumaCondicao = true;
    proximaQuestao.condicao.forEach((condicao, index) => {
      switch (condicao) {
        case '=':
          if (temperatura == proximaQuestao.valor[index]) {
            nenhumaCondicao = false;
            mostrarMensagem(proximaQuestao.mensagem[index], proximaQuestao.apontamento_respostas[index]);
          }
          break;
        case '<':
          if (temperatura < proximaQuestao.valor[index]) {
            nenhumaCondicao = false;
            mostrarMensagem(proximaQuestao.mensagem[index], proximaQuestao.apontamento_respostas[index]);
          }
          break;
        case '<=':
          if (temperatura <= proximaQuestao.valor[index]) {
            nenhumaCondicao = false;
            mostrarMensagem(proximaQuestao.mensagem[index], proximaQuestao.apontamento_respostas[index]);
          }
          break;
        case '>':
          if (temperatura > proximaQuestao.valor[index]) {
            nenhumaCondicao = false;
            mostrarMensagem(proximaQuestao.mensagem[index], proximaQuestao.apontamento_respostas[index]);
          }
          break;
        case '>=':
          if (temperatura >= proximaQuestao.valor[index]) {
            nenhumaCondicao = false;
            mostrarMensagem(proximaQuestao.mensagem[index], proximaQuestao.apontamento_respostas[index]);
          }
          break;
      }
    });
    if (nenhumaCondicao) {
      props.capturar();
    }
  }

  function mostrarMensagem(mensagem, index) {
    var apontamento = index;
    if (apontamento != 0) {
      apontamento = parseInt(questao.sequencia) + 1;
    }
    if (apontamento != 0) {
      Swal.fire({
        icon: 'info',
        title: 'Temperatura Corporal',
        html: '<h1>' + mensagem + '​</h1>',
        showConfirmButton: false,
        width: '800px',
        background: '#e3f3ff',
        timer: 5000
      }).then(() => {
        setProximaPergunta(undefined, undefined, apontamento)
      })
    }else{
      setProximaPergunta(undefined, undefined, apontamento)
    }
    
  }

  return (
    <>
      {console.log(props.questionario)}
      <div>
        <h1>{questao.questao}</h1>
      </div>
      <div>
        {questao.dispara_acao == 0 && questao.respostas.map((resposta, index) => {
          // console.log(resposta)
          return <button className="btn" type="submit" key={index}
            style={{ 
              color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20, margin: 30,
              minWidth: '200px'
            }}
            onClick={e => setProximaPergunta(resposta, index)}>{resposta}</button>
        })}
      </div>
    </>
  );
};

export default Questionario;