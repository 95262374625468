import React from "react";
import {Backspace, Send} from '@material-ui/icons';

const button = props => {


  const thisFontSize = props.thisFontSize;
  const thisMargin = props.thisMargin;
  const thisWidth = props.thisWidth;
  const thisHeight = props.thisHeight;
  const thisBackgroundColor = props.thisBackgroundColor;
  const thisColor = props.thisColor;
  const thisBorderColor = props.thisBorderColor;

  return (
    <button
      style={{
        margin: thisMargin === undefined ? 0.5 : parseInt(thisMargin),
        fontSize: thisFontSize === undefined ? 80 : parseInt(thisFontSize),
        fontFamily: "monospace",
        width: thisWidth === undefined ? 160 : parseInt(thisWidth),
        height: thisHeight === undefined ? 120 : parseInt(thisHeight),
        backgroundColor: thisBackgroundColor === undefined ? 'white' : thisBackgroundColor,
        color: thisColor === undefined ? '#1c7cc4' : thisColor,
        borderColor: thisBorderColor === undefined ? 'white' : thisBorderColor,
      }}
      className="btn"
      onClick={props.click}
    >
      {
      props.number === 'CORRIGE' ? 
      <Backspace style={{ fontSize: thisFontSize === undefined ? 80 : parseInt(thisFontSize) }} /> :
      props.number === 'OK' || props.number === 'CONFIRMAR' ? 
      <Send style={{ fontSize: thisFontSize === undefined ? 80 : parseInt(thisFontSize) }} /> :
      props.number
      }
    </button>
  );
};

export default button;