import React, { Component } from "react";

class RetornoComponent extends Component {
  render() {
    return (
      <div id="fullpage" className="fullpage-default">
        <div className="section animated-row" data-section="slide02">
          <div className="section-inner">
            <div className="about-section">
              <div className="container-sm">
                <div className="row align-items-start">
                  <div className="col">
                    <button
                      style={{ borderColor: "white", background: "transparent", color: "white", padding: 20, paddingBottom: 30, fontSize: 80 }}
                      type="button"
                      className="btn btn-secondary btn-lg">
                      Agendamento
                      <br></br>
                      <br></br>
                      Consulta
                    </button>
                  </div>
                  <div className="col">
                    <button
                      style={{ borderColor: "white", background: "transparent", color: "white", padding: 20, paddingBottom: 30, fontSize: 80 }}
                      type="button"
                      className="btn btn-secondary btn-lg">
                      Resultado
                      <br></br>
                      <br></br>
                      Exames
                    </button>
                  </div>
                </div>
                <div style={{ height: 200 }}></div>
                <div className="row align-items-end">
                  <div className="col">
                    <button
                      style={{ borderColor: "white", background: "transparent", color: "white", padding: 20, paddingBottom: 30, fontSize: 80 }}
                      type="button"
                      className="btn btn-secondary btn-lg">
                      Agendamento
                      <br></br>
                      <br></br>
                      Exames
                    </button>
                  </div>
                  <div className="col">
                    <button
                      style={{ borderColor: "white", background: "transparent", color: "white", padding: 20, paddingBottom: 30, fontSize: 80 }}
                      type="button"
                      className="btn btn-secondary btn-lg">
                      Impressão
                      <br></br>
                      <br></br>
                      Atestado
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default RetornoComponent;