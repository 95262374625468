import React, { useState, Component } from "react";
import Webcam from "react-webcam";
import Swal from 'sweetalert2';
import useInterval from '@use-it/interval';
import goTo from "../../static/goTo.jsx";

const routes = require('../../../config/router.js');

class AtualizarFotoComponent extends Component {
  render() {
    return (
      // <div className="section animated-row">
      //   <div className="section"
      //   style={{paddingTop:60}}>
      //     <div className="section-inner">
      //       <WebcamCapture />
      //     </div>
      //   </div>
      // </div>
      <div id="fullpage" className="fullpage-default">
        <div className="section animated-row" data-section="slide02">
          <div className="section-inner" style={{ paddingTop: 450 }}>
            <div className="row justify-content-center">
              <div className="col-md-7 wide-col-laptop">
                <div className="contact-section">
                  <div className="row justify-content-center">
                    <WebcamCapture />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// #################################
// ########## Camerazinha ##########
// #################################

const WebcamCapture = () => {
  const webcamRef = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [texto, setTexto] = React.useState('Capturar');
  const [numero, setNumero] = React.useState(0);
  const [start, setStart] = useState(false);

  const capturar = React.useCallback(() => {

    const image = webcamRef.current.getScreenshot();
    setImage(image);

    localStorage.setItem('foto', image);

    // ################################
    // ###  Pra cadastrar a imagem  ###
    // ################################
//      if (!localStorage.getItem('CadastroImagem-post_url')) {
//    searchImage(image);
//     	} else {
//  	getPostURL(localStorage.getItem('foto'), postUserIMG);
// }

  }, [webcamRef, setImage]);

  const goToReconhecido = () => {
    // window.location.href = routes.routes.frontend + 'reconhecido';
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    console.log(empresa);
    goTo('reconhecido', empresa.barramento)
  }

  function searchImage(image) {

    const empresa = JSON.parse(localStorage.getItem('empresa'));

    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ image, 'id_empresa': empresa.id })
    };

    console.log(json)

    fetch(routes.routes.backend + 'imageapi/search_img', json)
      .then(res => res.json())
      .then((result) => {
        console.log(result)
        const jsonTermo = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id_empresa: empresa.id
          })
        };

        let result_search_img = result;

console.log('hueeeee')
console.log(result)

       console.log('image-binary: ' + result.data[0].image_crop_binary);

        if (result.data.length > 0 && result.data[0].message === "User was not found, método que não salva") {

          console.log(jsonTermo)

          fetch(routes.routes.backend + 'termo_search', jsonTermo)
            .then(res => res.json())
            .then((result) => isTermoAceito(empresa, result, image, result_search_img));
          // .then((result) => console.log(result));

        } else if (result.data.length > 0 && result.data[0].message === "User found with following info. look for user_id") {
          // window.location.href = routes.routes.frontend + 'reconhecido';
          goTo('reconhecido', empresa.barramento)
        } else if (result.data.length > 0 && result.data[0].message === "user_id has not been set, método que não salva") {
          localStorage.setItem('CadastroImagem-post_url', result.data[0].post_url);
          fetch(routes.routes.backend + 'termo_search', jsonTermo)
            .then(res => res.json())
            .then((result) => isTermoAceito(empresa, result, image, result_search_img))
          // postUserIMG();
        } else {
          Swal.fire({
            title: 'Houve um problema',
            text: 'Por favor tente novamente.',
            icon: 'error',
          })
        }
      })
  }

  function isTermoAceito(empresa, result, image, result_search_img) {

    let retornarIsTermoAceito = false;

    Swal.fire({
      icon: 'success',
      title: '<h1>Pronto!<h1>',
      html: "<h4>Já tiramos a sua foto, deseja confirmar?</h4>\n<img style=max-width:400px; max-height400px; src='" + "data:image/jpeg;base64," + result_search_img.data[0].image_binary + "'>",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Capturar novamente',
      width: '800px',
      background: '#e3f3ff',
      // timer: 30000
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          html: 'Estamos carregando os dados do termo de aceite!',
          showConfirmButton: false,
          width: '800px',
          background: '#e3f3ff',
          didOpen: () => {
            Swal.showLoading()
          },
          timer: 15000,
        })
      } else if (result.isDenied) {
        console.log('capturar novamente')
        retornarIsTermoAceito = true;
      } else if (result.isDismissed) {
        console.log('capturar novamente')
        retornarIsTermoAceito = true;
      }
    }).then(() => {

      console.log('retornarIsTermoAceito:' + retornarIsTermoAceito)

      if (retornarIsTermoAceito) {
        return;
      }

      console.log(result)
      let id_termo = result.id;
      let texto_aceite = result.texto;

      const jsonUserTermo = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_empresa: empresa.id,
          id_termo: id_termo,
          user_id: localStorage.getItem('CadastroImagem-userid'),
        })
      };

console.log(jsonUserTermo)

      fetch(routes.routes.backend + 'user_termo_search', jsonUserTermo)
        .then(res => res.json())
        .then((result) => {
          console.log(result)
          if (result.length === 0) {
            Swal.fire({
              title: 'Termo de aceite',
              text: texto_aceite ? texto_aceite : 'Aceita o uso da sua imagem?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceitar',
              cancelButtonText: 'Recusar'
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  icon: 'success',
                  title: '<h1>Entendido!<h1>',
                  html: '<h4>Registramos sua foto no nosso banco de dados.<br>Vamos seguir com o seu atendimento</h4>',
                  showConfirmButton: false,
                  width: '800px',
                  background: '#e3f3ff',
                  timer: 10000
                })
                storeUserTermo(empresa.id, id_termo);
                getPostURL(image, postUserIMG);
              } else {
                // window.location.href = routes.routes.frontend + 'reconhecido';
                goTo('reconhecido', empresa.barramento)
              }
            })

          }
          else {
            postUserIMG()
          }

        });
    })

  }

  async function storeUserTermo(id_empresa, id_termo) {

    const jsonUserTermoStore = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_empresa,
        id_termo,
        user_id: localStorage.getItem('CadastroImagem-userid'),
        'datahora_aceite': new Date(),
      })
    };

    fetch(routes.routes.backend + 'user_termo_store', jsonUserTermoStore)
      .then(res => res.json())

  }

  async function getPostURL(image, callback) {

    const empresa = JSON.parse(localStorage.getItem('empresa'));

    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ image, 'id_empresa': empresa.id })
    };
    console.log(json)
    fetch(routes.routes.backend + 'imageapi/verify_img', json)
      .then(res => res.json())
      .then((result) => {
        console.log(result)
        if (result.data.length && result.data[0].message === "user_id has not been set, please send post request to post_url with user_id"
          || result.data[0].message === "User with this face does not exists, however profile is created. please send post request to post_url with user_id") {
          if (result.data.length) {
            localStorage.setItem('CadastroImagem-post_url', result.data[0].post_url);
            localStorage.removeItem('mostrar_termo')
            callback();
          } else {
            Swal.fire({
              icon: 'info',
              title: '<h1>Desculpe<h1>',
              html: '<h4>Não conseguimos lhe identificar. Por favor, tente novamente.</h4>',
              showConfirmButton: false,
              width: '800px',
              background: '#e3f3ff',
              timer: 4000
            })
          }
        } else if (result.data && result.data[0].message == "User found with following info. look for user_id") {
          // window.location.href = routes.routes.frontend + 'reconhecido';
          goTo('reconhecido', empresa.barramento)
        } else if (result.data && !result.data[0].message) {
          Swal.fire({
            icon: 'info',
            title: '<h1>Desculpe<h1>',
            html: '<h4>Não conseguimos lhe identificar. Por favor, tente novamente.</h4>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 4000
          })
        }
      })
  }

  function postUserIMG() {
    setTimeout(function () {
      const content = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          post_url: localStorage.getItem('CadastroImagem-post_url'),
          user_id: localStorage.getItem('CadastroImagem-userid'),
          id_empresa: JSON.parse(localStorage.getItem('empresa')).id,
        })
      };

      console.log(routes.routes.backend + 'imageapi/show', content)

      fetch(routes.routes.backend + 'imageapi/show', content)
        .then(res => res.json())
        .then((result) => {
          console.log(result)
          if (result.message === "User profile saved successfully!") {

            /* Swal.fire({
               icon: 'success',
               title: 'Cadastro realizado, redirecionando...',
               showConfirmButton: false,
               width: '800px',
               background: '#e3f3ff',
               timer: 3000
             })*/

            setTimeout(goToReconhecido(), 3000)
          }
        })
    }, 5000)
  }

  const carregando = () => {
    let timerInterval
    Swal.fire({
      title: 'Aguarde!',
      timer: 20000,
      background: '#e3f3ff',
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    })
  }

  const Capturar = () => {
    const [texto, setTexto] = React.useState('Capturar');
    const [segundos, setSegundos] = React.useState(4);
    const [isRunning, setIsRunning] = useState(false);

    useInterval(
      () => {
        setSegundos(segundos - 1);
        console.log(segundos);
        console.log(segundos == 6 ? 'Capturar' :
          segundos <= 5 && segundos > 0 ? 'Capturando em ' + segundos :
            'Capturando')
        if (segundos == 6 && isRunning) {
          setTexto('Capturar')
        } else if (segundos <= 5 && segundos > 0) {
          setTexto('Capturando em ' + segundos)
        } else {
          setTexto('Capturando');
          setIsRunning(false)
          capturar();
          carregando();
          searchImage(localStorage.getItem('foto'))
        }
      },
      isRunning ? 1000 : null
    );

    const capturarDelay = () => {
      setIsRunning(true)
    }

    return (
      <button className="btn" type="submit"
        style={{ color: 'white', background: 'green', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
        onClick={capturarDelay}
      >{texto}</button>
    );
  };

  return (
    <div className="col-md-7 wide-col-laptop">
      {/* <div className="wide-col-laptop"> */}
      <div className="contact-section">
        <div className="row justify-content-center">
          <div className="col-md-12 animate" style={{ paddingTop: 200 }} data-animate="fadeInUp">
            <Webcam
              audio={false}
              ref={webcamRef}
              width={422}
              height={550}
              screenshotFormat="image/jpeg"
              style={{ top: -340 }}
            />
            <div style={{ paddingTop: 20, fontSize: 20 }}>
              Sr(a) {localStorage.getItem('nome')}, vamos cadastrar sua face em nosso sistema. Por favor, posicione-se em frente da câmera e clique em CAPTURAR.
              <br></br>
              Se estiver de máscara, o sistema irá focar na região dos olhos. Por favor, mantenha o uso da máscara!
              <br></br>
              Caso não queira tirar uma foto, clique em PULAR.
            </div>
          </div>
          <div style={{ paddingTop: 20 }} className="col-md-3 animate" data-animate="fadeInUp">
            <Capturar />
          </div>
          <div style={{ paddingTop: 20 }} className="col-md-3 animate" data-animate="fadeInUp">
            <button className="btn btn-secondary btn-lg" type="submit"
              style={{ color: 'white', background: 'grey', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
              onClick={goToReconhecido}
            >Pular</button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AtualizarFotoComponent;
