import React, { useState, Component } from "react";
import goTo from "../static/goTo.jsx";
import Swal from 'sweetalert2';

const routes = require('../../config/router.js');

class MedicoComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profissionaisReferencia: JSON.parse(localStorage.getItem('profissionaisReferencia')),
      medicosGestores: JSON.parse(localStorage.getItem('medicosGestores'))
    };
  }

  componentDidMount() {
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    if (!localStorage.getItem('medicosGestores') && !localStorage.getItem('medicosGestores')) {
      const json = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          'id_usuario': localStorage.getItem('CadastroImagem-userid'),
          'loginv3': empresa.loginv3,
          'senhav3': empresa.senhav3,
          'KeyDS': empresa.keyds,
          'linkv3': empresa.linkv3,
        })
      };
      console.log(JSON.parse(json.body))
      fetch(routes.routes.backend + 'profissionais/listar', json)
        .then(res => res.json())
        .then(result => {
          console.log(result.profissionaisReferencia)
          console.log(result.medicosGestores)
          localStorage.setItem('medicosGestores', JSON.stringify(result.medicosGestores))
          localStorage.setItem('profissionaisReferencia', JSON.stringify(result.profissionaisReferencia))
          window.location.reload();
        })
    }
  }

  setMedico(selecionado) {
    localStorage.setItem('medicoSelecionado', JSON.stringify(selecionado))
    let timerInterval;
    Swal.fire({
      icon: 'info',
      html: 'Carregando agenda.',
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    })
    this.getAgendaMedico(selecionado);
  }

  getAgendaMedico(medico) {
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    var date = new Date()
    var dia = date.getDate();
    var mes = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var ano = date.getFullYear();
    var dataAPartirDe = ano + '-' + mes + '-' + dia

    let nomeMedico = medico.nome.replaceAll(" ", "%20")

    let json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'nomeMedico': nomeMedico,
        'dataAPartirDe': dataAPartirDe,
        'loginv3': empresa.loginv3,
        'senhav3': empresa.senhav3,
        'KeyDS': empresa.keyds,
        'linkv3': empresa.linkv3,
      })
    };

    console.log(routes.routes.backend + 'agenda/listar')
    console.log(json)

    fetch(routes.routes.backend + 'agenda/listar', json)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        if (!result.length) {
          Swal.fire({
            icon: 'info',
            title: 'Agenda indisponível no momento.',
            html: '<h1>Dirija-se à recepção para mais informações.​</h1>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000
          }).then(() => {
            console.log('home')
            //  goTo('home', empresa.barramento)
          })
        } else {
          localStorage.setItem('agendaConsultaLista', JSON.stringify(result))
          // console.log('consulta-agenda')
          goTo('consulta-agenda', empresa.barramento)
        }
      })
  }

  render() {
    console.log(this.state.profissionaisReferencia)
    console.log(this.state.medicosGestores)
    setTimeout(() => {
      goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
    }, 150000);
    return (
      <div className="section animated-row">
        <div className="section">
          <div className="section-inner">
            <div className="welcome-box">
              <h1 style={{ fontSize: 50 }}>
                Selecione o profissional da saúde:
              </h1>
              <div className="section-inner">
                <div className="row justify-content-center">
                  <div className="col-md-7 wide-col-laptop">
                    <div className="contact-section">
                      <div className="row justify-content-center">
                        {this.state.profissionaisReferencia &&
                          this.state.profissionaisReferencia.map((profissionalReferencia, index) =>
                            // console.log(profissionalReferencia.nome)
                            <div className="col-md-6 animate" style={{ paddingTop: 20, paddingBottom: 20 }} key={index}>
                              <button className="btn" type="submit" key={'pr' + index}
                                style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 40 }}
                                onClick={e => this.setMedico(profissionalReferencia)}
                              >{profissionalReferencia.nome}</button>
                            </div>
                          )}
                        {this.state.medicosGestores &&
                          this.state.medicosGestores.map((medicoGestor, index) =>
                            <div className="col-md-6 animate" style={{ paddingTop: 20, paddingBottom: 20 }} key={'m' + index}>
                              <button className="btn" type="submit" key={'mg' + index}
                                style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30 }}
                                onClick={e => this.setMedico(medicoGestor)}
                              >{medicoGestor.nome}</button>
                            </div>
                          )}
                        <div className="col-md-6 animate" style={{ paddingTop: 20, paddingBottom: 20 }} key={'maoe'}>
                          <button className="btn" type="submit" key={'maaaaaaaaaaaaaoe'}
                            style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 40 }}
                            onClick={e => this.setMedico({ "id": 589128, "nome": "Administrador" })}
                          >Administrador</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MedicoComponent;