
import teste from '../../assets/videos/careplus.mp4';
import careplus from '../../assets/videos/careplus.mp4';
import doc from '../../assets/videos/doc.mp4';
import rioVerde from '../../assets/videos/rio_verde.mp4';
import messi from '../../assets/videos/messi.mp4';

const Videos = (props) => {
    const search = window.location.href;
    let match = search.match(/emp=(.*)&/);
    if(!match){
        match = search.match(/emp=(.*)/)
    }
    const emp = match?.[1];
    const empresa = { barramento: emp };
    if(!empresa || empresa.barramento === "teste"){
        return (
            <video autoPlay muted loop id="background">
                <source src={teste} type="video/mp4" />
            </video>
        );
    } else if (empresa.barramento === "careplus"){
        return (
            <video autoPlay muted loop id="background">
                <source src={careplus} type="video/mp4" />
            </video>
        );
    } else if (empresa.barramento === "doc"){
        return (
            <video autoPlay muted loop id="background">
                <source src={doc} type="video/mp4" />
            </video>
        );
    }  else if (empresa.barramento === "rio_verde"){
        return (
            <video autoPlay muted loop id="background">
                <source src={rioVerde} type="video/mp4" />
            </video>
        );
    } else {
        return (
            <video autoPlay muted loop id="background">
                <source src={messi} type="video/mp4" />
            </video>
        );
    }
};

export default Videos;