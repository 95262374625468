import HomeComponent from "../home/HomeComponent";
import CapsulaOcupadoComponent from "../capsula/CapsulaOcupadoComponent";
import ReconhecidoComponent from "../reconhecimento/reconhecido/ReconhecidoComponent";
import AtualizarFotoComponent from "../reconhecimento/reconhecido/AtualizarFotoComponent";
import NaoReconhecidoComponent from "../reconhecimento/naoreconhecido/NaoReconhecidoComponent";
import MedicoComponent from "../agendamento/MedicoComponent";
import ConsultaComponent from "../agendamento/ConsultaComponent";
import RetornoComponent from "../retorno/RetornoComponent";

export default [
  {
    path: "/reconhecido",
    component: ReconhecidoComponent
  },
  {
    path: "/naoreconhecido",
    component: NaoReconhecidoComponent
  },
  {
    path: "/attfoto",
    component: AtualizarFotoComponent
  },
  {
    path: "/retorno",
    component: RetornoComponent
  },
  {
    path: "/home",
    component: HomeComponent
  },
  {
    path: "/capsulaocupada",
    component: CapsulaOcupadoComponent
  },
  {
    path: "/medico-agenda",
    component: MedicoComponent
  },
  {
    path: "/consulta-agenda",
    component: ConsultaComponent
  },
];
