import { React, Component } from "react";

import fotoUsu from "../../../assets/images/usuario.png";
import Swal from 'sweetalert2';
import goTo from "../../static/goTo";

const routes = require('../../../config/router.js');

class ReconhecidoComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      image: undefined,
      empresa: JSON.parse(localStorage.getItem('empresa')),
    };
    this.getUserImg();
  }

  confirmar() {
    if (JSON.parse(sessionStorage.getItem('totem'))) {
      Swal.fire({
        icon: 'success',
        title: JSON.parse(sessionStorage.getItem('totem')).mensagem_abrir_totem ?
          JSON.parse(sessionStorage.getItem('totem')).mensagem_abrir_totem :
          'A cápsula está aberta',
        showConfirmButton: false,
        width: '800px',
        background: '#e3f3ff',
        timer: 10000
      }).then(() => {
        goTo('capsulaocupada', JSON.parse(localStorage.getItem('empresa')).barramento);
      })
    } else {
      this.confirmarAgendamento()
    }
  }

  confirmarAgendamento() {

    const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));
    const login = jsonEmpresa.loginv3;
    const senha = jsonEmpresa.senhav3;
    const KeyDS = jsonEmpresa.keyds;
    const linkv3 = jsonEmpresa.linkv3;
    const endereco_healthmap = jsonEmpresa.endereco_healthmap;

    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'username': login,
        'password': senha,
        'KeyDS': KeyDS,
        'linkv3': linkv3,
        'endereco_healthmap': endereco_healthmap,
        'Authorization': localStorage.getItem('Authorization'),
        idConsulta: localStorage.getItem('idConsulta'),
      })
    }

    const post = routes.routes.backend + 'consulta/update';
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    console.log(json)
    fetch(post, json)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        if (result.status === "Status alterado para Em Espera.") {
					console.log(result)
	  if(empresa.raspberry != null || empresa.raspberry_backup != null) {
					this.triggerVideo()
	  }
          // console.log('Consulta com id ' + proxConsulta.idConsulta + ' alterada para Em Espera')
          //Ligar Iluminação e Abrir Porta Capsula
          this.ligarLed(1);
          this.abrirPorta(1);
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Consulta Confirmada!',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
          }).then(() => {
            // window.location.href = routes.routes.frontend + 'home'
            localStorage.clear();
            goTo('home', jsonEmpresa.barramento)
          })
        } else {

          Swal.fire({
            // position: 'top-end',
            icon: 'erro',
            title: 'Houve um problema ao confirmar sua consulta. Dirija-se à recepção...',
            showConfirmButton: true,
            confirmButtonText: 'Voltar para o Início',
            width: '800px',
            background: '#e3f3ff',
            timer: 10000
          }).then(() => {
            localStorage.clear();
            goTo('home', jsonEmpresa.barramento)
          })
        }
      });
  }

	getUrlVideoConsultaEspera(consultas) {
    for (var i = 0; i < consultas.length; i++) {
      var consulta = consultas[i];
      let date = new Date();
      
			const dateConsulta = new Date(consulta.data + ' ' + consulta.horaFim);

      // let dateConsultaConsideraAtraso = new Date(consulta.data + ' ' + consulta.horaFim);
      // dateConsultaConsideraAtraso.setHours(dateConsulta.getHours() + 2);

      if (dateConsulta.getDay() == date.getDay()) {
        if (consulta.status === 'ESPERA') {
          console.log('ESPERA')
          return consulta.urlVideo;
        }
      } 	
    }
  }

	triggerVideo(){
		const id = localStorage.getItem('idUser');
		const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));
    const KeyDS = jsonEmpresa.keyds;
    const endereco_healthmap = jsonEmpresa.endereco_healthmap;
		const url = routes.routes.backend + 'consulta/show_all';

    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
				id,
				KeyDS,
				'username': jsonEmpresa.loginv3,
				'password': jsonEmpresa.senhav3,
				'KeyDS' : jsonEmpresa.keyds,
				'endereco_healthmap' : jsonEmpresa.endereco_healthmap,
				'linkv3' : jsonEmpresa.linkv3,
      })
    }

		// console.log(json.body);
		const idTotem = sessionStorage.getItem('totem') ? JSON.parse(sessionStorage.getItem('totem')).id : 0;

		console.log("idTotem %s", idTotem);

		fetch(url, json)
			.then(res => res.json())
			.then(result => {
				const urlVideo = this.getUrlVideoConsultaEspera(result)
        console.log(urlVideo);
        let videoUrl = (endereco_healthmap + 'joinvideo?videoURL=' + urlVideo + '&guestId=' + id + '&guestName=' + localStorage.getItem('nome') + '&totemId=' + idTotem).replaceAll(" ", "%20")
        const startVideo = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            videoUrl: videoUrl,
            idTotem: idTotem
          })
        }
        fetch(routes.routes.serverVideo + 'startVideo', startVideo)
          .then(res => res.json())
          .then(result => {
            console.log(JSON.stringify(result))
          })
      })
	}

  async abrirPorta(index){
    if(index > 5){
      return
    }
    const totem = JSON.parse(sessionStorage.getItem('totem'));
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    console.log(totem);
    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'abrirPorta': 'true',
        'idTotem': totem ? totem.id : 0
      })
    }

    if(empresa.raspberry != null || empresa.raspberry_backup != null) {
	    let post;
	    if(empresa.usar_backup){ 
	      post = empresa.raspberry_backup + 'magnetizador';
	    } else { 
	      post = empresa.raspberry + 'magnetizador';
	    }
    
	    fetch(post, json)
	    .then(res => res.json())
	    .then(result => {
	      var jsonRes = JSON.parse(result);
	      while(jsonRes.sucesso !== 'true'){
	        this.abrirPorta(++index);
	      }
	    });
    }
  }

  async ligarLed(index){
    if(index > 5){
      return
    }
    const totem = JSON.parse(sessionStorage.getItem('totem'));
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'estadoIluminacao': 'false',
        'idTotem': totem ? totem.id : 0
      })
    }
    if(empresa.raspberry != null || empresa.raspberry_backup != null) {
      let post;
      if(empresa.usar_backup){ 
        post = empresa.raspberry_backup + 'iluminacao';
      } else { 
        post = empresa.raspberry + 'iluminacao';
      }
      fetch(post, json)
      .then(res => res.json())
      .then(result => {
        var jsonRes = JSON.parse(result);
        while(jsonRes.sucesso !== 'true'){
          this.ligarLed(++index);
        }
      });
    }
  }

  async getUserImg() {
    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('CadastroImagem-userid'),
        id_empresa: localStorage.getItem('id_empresa')
      })
    }

    const post = routes.routes.backend + 'imageapi/getUserv2';
    console.log(json)

    fetch(post, json)
      .then(res => res.json())
      .then(res => {
        console.log(res.image)
        if (res && res.image) {
          this.setState({ image: res.image })
        } else {
          this.getUserImg()
        }
      })

    // const img = localStorage.getItem('foto') === undefined || localStorage.getItem('foto') === null ? fotoUsu : localStorage.getItem('foto');
    // return img;
  }

  recheckConsulta() {
    if (!localStorage.getItem('CadastroImagem-userid')) {
      return
    }
    const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'))
    const json = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: localStorage.getItem('CadastroImagem-userid'),
        'username': jsonEmpresa.loginv3,
        'password': jsonEmpresa.senhav3,
        'linkv3': jsonEmpresa.linkv3,
        'KeyDS': jsonEmpresa.keyds,
        'endereco_healthmap': jsonEmpresa.endereco_healthmap,
      })
    }

    console.log(json)

    let beneficiario;

    fetch(routes.routes.backend + 'consulta/show_data', json)
      .then(res => res.json())
      .then((result) => {
        beneficiario = result;
        if (beneficiario === undefined || beneficiario.type === 'invalid-json') {
          console.log('Não existe cadastro com o id dessa pessoinha')
          localStorage.setItem('mensagem', 'Você está com problemas no cadastro. Dirija-se à recepção.')
          // window.location.href = routes.routes.frontend + 'reconhecido';
          goTo('reconhecido', jsonEmpresa.barramento);
          return;
        }

        console.log(beneficiario)
        console.log("### beneficiario ###");

        // verifica se tem consulta com status CONFIRMADO hoje 
        let consultasHoje = [];

        const jsonEmEspera = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // status, 
            id: localStorage.getItem('CadastroImagem-userid'),
            'username': jsonEmpresa.loginv3,
            'password': jsonEmpresa.senhav3,
            'KeyDS': jsonEmpresa.keyds,
            'endereco_healthmap': jsonEmpresa.endereco_healthmap,
            'linkv3': jsonEmpresa.linkv3,
          })
        }

        fetch(routes.routes.backend + 'consulta/show_all', jsonEmEspera)
          .then(res => res.json())
          .then((consultas) => {
            consultas.reverse();
            console.log(consultas)

            let date = new Date();
            // console.log(date.getHours() + ':' + date.getMinutes())

            if (consultas.length === 0) {
              Swal.fire({
                icon: 'info',
                title: 'Não localizamos sua consulta.',
                html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                showConfirmButton: false,
                width: '800px',
                background: '#e3f3ff',
                timer: 7000
              }).then(() => {
                localStorage.clear();
                goTo('home', jsonEmpresa.barramento);
              })
            } else {
              // consultas.map(function (consulta) {
              for (var i = 0; i < consultas.length; i++) {
                var consulta = consultas[i];
                let date = new Date();
                // console.log(date.getHours() + ':' + date.getMinutes())

                const dateConsulta = new Date(consulta.data + ' ' + consulta.horaFim);
                // console.log(dateConsulta.getHours() + ':' + dateConsulta.getMinutes())

                let dateConsultaConsideraAtraso = new Date(consulta.data + ' ' + consulta.horaFim);
                dateConsultaConsideraAtraso.setHours(dateConsulta.getHours() + 2);
                //console.log(dateConsultaConsideraAtraso.getHours() + ':' + dateConsultaConsideraAtraso.getMinutes())

                if (dateConsulta.getDay() == date.getDay()) {
                  if (consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
                    console.log(consulta)
                    console.log('CONFIRMADO || AGENDADO')
                    this.verificarConsultas(consulta, beneficiario)
                  } else if (consulta.status === 'ESPERA') {
                    console.log(consulta)
                    console.log('ESPERA')

                    Swal.fire({
                      icon: 'info',
                      title: 'Consulta já confirmada. Por favor aguarde',
                      // html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                      showConfirmButton: false,
                      width: '800px',
                      background: '#e3f3ff',
                      timer: 7000
                    }).then(() => {
                      // window.location.href = routes.routes.frontend + 'home'; 
                      localStorage.clear();
                      goTo('home', jsonEmpresa.barramento);
                    })
                  } else {
                    if (i == (consultas.length - 1)) {
                      Swal.fire({
                        icon: 'info',
                        title: 'Não encontramos sua consulta',
                        html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                        showConfirmButton: false,
                        width: '800px',
                        background: '#e3f3ff',
                        timer: 7000
                      }).then(() => {
                        localStorage.clear();
                        goTo('home', jsonEmpresa.barramento);
                      })
                    }
                  }
                } else if (dateConsultaConsideraAtraso.getTime() > date.getTime()
                  && dateConsultaConsideraAtraso.getDay() == date.getDay()) {
                  if (consulta.status === 'ATRASADO' || consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
                    console.log(consulta)
                    console.log('ATRASADO')
                    this.verificarConsultas(consulta, beneficiario);
                    break;
                  }
                } else if (i == (consultas.length - 1)) {
                  Swal.fire({
                    icon: 'info',
                    title: 'Não encontramos sua consulta',
                    html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                    showConfirmButton: false,
                    width: '800px',
                    background: '#e3f3ff',
                    timer: 7000
                  })
                    .then(() => {
                      // window.location.href = routes.routes.frontend + 'home'; 
                      goTo('home', jsonEmpresa.barramento);
                    })
                }
              }
            }

          }, (error) => {
            console.log(error)
            console.log(json);
            Swal.fire({
              icon: 'info',
              title: 'Desculpe',
              html: '<h4>Houve um problema ao buscar as consultas em espera. Tente novamente, por favor</h4>',
              showConfirmButton: false,
              width: '800px',
              background: '#e3f3ff',
              timer: 4000
            })
          })
      }, (error) => {
        console.log(error)
      })
  }

  verificarConsultas(consulta, beneficiario) {
    const Authorization2 = 'Basic ' + Buffer.from('hmwsclient:healthm@pws.client').toString('base64');
    const idConsulta = consulta.idConsulta;
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    localStorage.clear();

    console.log(beneficiario.nome);
    console.log(consulta.nomeProfissional);
    console.log(this.unformatDate(consulta.data));
    console.log(consulta.horaInicio + ' a ' + consulta.horaFim);

    localStorage.setItem('nome', beneficiario.nome);
    localStorage.setItem('id_empresa', empresa.id);
    localStorage.setItem('CadastroImagem-userid', beneficiario.id);
    localStorage.setItem('documento', beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro');
    localStorage.setItem('medico', consulta.nomeProfissional);
    localStorage.setItem('dataConsulta', this.unformatDate(consulta.data));
    localStorage.setItem('horario', consulta.horaInicio + ' a ' + consulta.horaFim);
    localStorage.setItem('Authorization', Authorization2);
    localStorage.setItem('idConsulta', idConsulta);
    localStorage.setItem('reconhecido', empresa);
    goTo('reconhecido', empresa.barramento);
  }


  unformatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  render() {

    const nome = localStorage.getItem('nome') === undefined ? "" : localStorage.getItem('nome');
    const documento = localStorage.getItem('documento') === undefined ? "" : localStorage.getItem('documento');
    const medico = localStorage.getItem('medico') === undefined ? "" : localStorage.getItem('medico');
    const dataConsulta = localStorage.getItem('dataConsulta') === undefined ? "" : localStorage.getItem('dataConsulta');
    const horario = localStorage.getItem('horario') === undefined ? undefined : localStorage.getItem('horario');
    const mensagem = localStorage.getItem('mensagem') === undefined ? "" : localStorage.getItem('mensagem');
    // const img = localStorage.getItem('foto') === undefined || localStorage.getItem('foto') === null ? this.getUserImg() : localStorage.getItem('foto');

    if (!dataConsulta && !medico && !horario) {
      this.recheckConsulta();
    }
    let page = null;

    if (mensagem !== undefined && mensagem !== null && mensagem !== '') {
      page = (
        <div className="section animated-row">
          <div className="section-inner">
            <div className="about-section">
              <div className="row justify-content-center">
                <div className="col-lg-10 wide-col-laptop">
                  <div className="row">
                    <div className="col-md-12 animate" data-animate="fadeInUp">
                      <div className="contact-box" style={{ textAlign: "center", fontSize: 50 }}>
                        <i className="fa fa-bell" />
                        {mensagem}
                        <i className="fa fa-bell" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      page = (
        <div className="section animated-row">
          <div className="section-inner">
            <div className="about-section">
              <div className="row justify-content-center">
                <div className="col-lg-8 wide-col-laptop">
                  <div className="row">
                    <div className="col-md-6">
                      <figure className="about-img animate" data-animate="fadeInUp">
                        <img src={this.state.image ? this.state.image : fotoUsu} className="rounded" alt="imagem_reconhecido" style={{ width: 440 }} />
                      </figure>
                    </div>
                    <div className="col-md-6 animate" data-animate="fadeInUp">
                      <div className="contact-box">
                        <div className="contact-row">
                          Nome: <strong><div className="large">&nbsp;{nome}</div></strong>
                        </div>
                        {/* <div className="contact-row">
                          Documento: <strong><div className="large">&nbsp;{documento}</div></strong>
                        </div> */}
                        <div className="contact-row">
                          Profissional de saúde: <strong><div className="large">&nbsp;{medico}</div></strong>
                        </div>
                        <div className="contact-row">
                          Data da Consulta: <strong><div className="large">&nbsp;{dataConsulta}</div></strong>
                        </div>
                        <div className="contact-row">
                          Horário: <strong><div className="large">&nbsp;{horario}</div></strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 animate" data-animate="fadeInUp">
                      <div className="contact-box" style={{ textAlign: "center", fontSize: 45 }}>
                        Deseja confirmar consulta?
                      </div>
                    </div>
                    <div className="col-md-12 animate row " data-animate="fadeInUp">
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <button className="btn" type="submit"
                          style={{ color: 'white', background: 'green', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20, width: "100%" }}
                          onClick={(e) => this.confirmar()}
                        >Sim</button>
                      </div>
                      <div className="col-md-6 animate" data-animate="fadeInUp">
                        <button className="btn btn-secondary btn-lg" type="submit"
                          style={{ color: 'white', background: 'red', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20, width: "100%" }}
                          onClick={() => goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)}
                        >Não</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (mensagem === undefined) {
      setTimeout(() => {
        goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
      }, 10000);
    } else {
      setTimeout(() => {
        goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento);
      }, 30000);
    }

    return (
      <div>
        {page}
      </div>
    );
  }
}

export default ReconhecidoComponent;
