
import React, { Component, useEffect } from "react";
// import WebcamCapture from "./WebcamCapture";
import goTo from "../static/goTo.jsx";

import Webcam from "react-webcam";
import Swal from 'sweetalert2';

import Questionario from "../utils/Questionario/Questionario"

const { forwardRef, useRef, useImperativeHandle } = React;


const routes = require('../../config/router.js');
// class HomeComponent extends Component {
const HomeComponent = (props) => {

  const childRef = useRef();

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     empresa: JSON.parse(localStorage.getItem('empresa')),
  //     estadoAtendimento: "Esperando",
  //     capturar: false,
  //     childRef = useRef()
  //   }
  // }

  const [estadoAtendimento, setEstadoAtendimento] = React.useState("Esperando");
  const [questionario, setQuestionario] = React.useState(null);

  const questionarioValidation = () => {
    console.log(questionario);
    if (questionario && questionario.length > 0)
      setEstadoAtendimento("Questionario")
    else
      childRef.current.capturar()
  }

  useEffect(() => {
    if (sessionStorage.getItem('totem') && JSON.parse(sessionStorage.getItem('totem')).is_capsula
      && JSON.parse(localStorage.getItem('empresa')) && JSON.parse(localStorage.getItem('empresa')).id) {
      const json = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          'id_totem_cadastro': JSON.parse(sessionStorage.getItem('totem')).id,
          'id_empresa': JSON.parse(localStorage.getItem('empresa')).id
        })
      };

      fetch(routes.routes.backend + 'totem_status_search', json)
        .then((res) => res.json())
        .then((res) => {
          // console.log(res)
          if (res) {
            if (res.id_status != 1) {
              goTo('capsulaocupada', JSON.parse(localStorage.getItem('empresa')).barramento)
            }
          } else {
            const jsonStore = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                'id_totem_cadastro': JSON.parse(sessionStorage.getItem('totem')).id,
                'id_empresa': JSON.parse(localStorage.getItem('empresa')).id,
                'id_status': 1,
              })
            };
            // console.log(jsonStore)
            fetch(routes.routes.backend + 'totem_status_store', jsonStore)
              .then((res) => console.log(res))
          }
        })
    }
    console.log(localStorage.getItem('empresa'))
    setTimeout(function () {
      var QuestionarioSearch = setInterval(function () {
        if (JSON.parse(localStorage.getItem('empresa')) && JSON.parse(localStorage.getItem('empresa')).id) {
          const json = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              // 'id_totem_cadastro': JSON.parse(sessionStorage.getItem('totem')).id,
              'id_empresa': JSON.parse(localStorage.getItem('empresa')).id,
              'id_pop': 1
            })
          };

          fetch(routes.routes.backend + 'questionario_search', json)
            .then((res) => res.json())
            .then(result => {
              console.log(result)
              setQuestionario(result)
            })
            .then(clearInterval(QuestionarioSearch))
        }
      }, 500);
    }, 100);
  }, []);

  return (
    <div className="section animated-row">
      <div className="section">
        <div className="section-inner">
          <WebcamCapture ref={childRef} />
          {estadoAtendimento == "Esperando" &&
            <button className="btn" type="submit"
              style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
              onClick={e => questionarioValidation()}>Iniciar Atendimento​</button>
          }
          {estadoAtendimento == "Questionario" &&
            <Questionario questionario={questionario} capturar={childRef.current.capturar} />
          }
          {estadoAtendimento == "Capturar" && questionario &&
            <button className="btn" type="submit"
              style={{ color: 'white', background: 'transparent', borderColor: 'white', fontSize: 30, paddingTop: 20, paddingBottom: 20 }}
              onClick={() => childRef.current.capturar}
            >​Capturar</button>
          }
        </div>
      </div>
    </div>
  );
  // }
}

function unformatDate(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1], day = datePart[2];

  return day + '/' + month + '/' + year;
}

const WebcamCapture = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({

    capturar() {
      capturar();
    }

  }));
  const empresa = JSON.parse(localStorage.getItem('empresa'));

  localStorage.clear();

  localStorage.setItem('empresa', JSON.stringify(empresa));

  const webcamRef = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [_capturar, set_capturar] = React.useState(props.capturar);

  const capturar = React.useCallback(() => {

    const empresa = JSON.parse(localStorage.getItem('empresa'));
    console.log(localStorage.getItem('empresa'));
    localStorage.clear();
    localStorage.setItem('empresa', JSON.stringify(empresa));

    const image = webcamRef.current.getScreenshot();

    setImage(image);

    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ image, 'id_empresa': empresa.id })
    };

    Swal.fire({
      position: 'center',
      icon: 'info',
      title: 'AGUARDE',
      text: 'Estamos pesquisando suas opções de atendimento.',
      didOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
      timer: 10000,
    })

    fetch(routes.routes.backend + 'imageapi/search_img', json)
      .then(res => res.json())
      .then((result) => {

        if (result != null && result.data != null && result.data.length === 0) {

          localStorage.setItem('image', image)

          // window.location.href = routes.routes.frontend + 'naoreconhecido';
          goTo('naoreconhecido', empresa.barramento);
          return;

        } else if (result != null && result.data != null && result.data[0].message === "User found with following info. look for user_id") {

          // ##############################
          // #####  Entrou na camera  #####
          // ##############################

          console.log(result.data[0])
          const id = result.data[0].user.user_id;
          //const id = 1415; // 101 - 309 - 812 - 1415 -> testes
          const KeyDS = routes.routes.keyDS;

          const jsonEmpresa = JSON.parse(localStorage.getItem('empresa'));

          // const Authorization = 'Basic ' + Buffer.from(jsonEmpresa.loginv3 + ':' + jsonEmpresa.senhav3).toString('base64');

          const json = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id,
              'username': jsonEmpresa.loginv3,
              'password': jsonEmpresa.senhav3,
              'linkv3': jsonEmpresa.linkv3,
              'KeyDS': jsonEmpresa.keyds,
              'endereco_healthmap': jsonEmpresa.endereco_healthmap,
            })
          }

          console.log(json)

          let beneficiario;

          fetch(routes.routes.backend + 'consulta/show_data', json)
            .then(res => res.json())
            .then((result) => {
              beneficiario = result;
              if (beneficiario === undefined || beneficiario.type === 'invalid-json') {
                console.log('Não existe cadastro com o id dessa pessoinha')
                localStorage.setItem('mensagem', 'Você está com problemas no cadastro. Dirija-se à recepção.')
                // window.location.href = routes.routes.frontend + 'reconhecido';
                goTo('reconhecido', empresa.barramento);
                return;
              }

              console.log(beneficiario)
              console.log("### beneficiario ###");


              Swal.fire({
                position: 'center',
                icon: 'success',
                title: '<h1><strong>Bem-Vindo Sr(a) ' + beneficiario.nome + '</strong></h1>',
                text: 'Estamos coletando algumas informações. Por favor, aguarde...​',
                showConfirmButton: false,
                didOpen: () => {
                  Swal.showLoading()
                },
                // showCancelButton: true,
                // confirmButtonText: 'Sim, este(a) sou eu',
                // cancelButtonText: 'Não sou ' + beneficiario.nome,
                timer: 15000
              });

              // verifica se tem consulta com status CONFIRMADO hoje 
              let consultasHoje = [];

              const jsonEmEspera = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  // status, 
                  id,
                  KeyDS,
                  'username': jsonEmpresa.loginv3,
                  'password': jsonEmpresa.senhav3,
                  'KeyDS': jsonEmpresa.keyds,
                  'endereco_healthmap': jsonEmpresa.endereco_healthmap,
                  'linkv3': jsonEmpresa.linkv3,
                })
              }

              fetch(routes.routes.backend + 'consulta/show_all', jsonEmEspera)
                .then(res => res.json())
                .then((consultas) => {
                  consultas.reverse();
                  console.log(consultas)

                  let date = new Date();
                  // console.log(date.getHours() + ':' + date.getMinutes())

                  if (consultas.length === 0) {
                    //  Swal.fire({
                    //      icon: 'info',
                    //      title: 'Não localizamos sua consulta.',
                    //      html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                    //      showConfirmButton: false,
                    //      width: '800px',
                    //      background: '#e3f3ff',
                    //      timer: 7000
                    //  }).then(() => {
                    if (sessionStorage.getItem('totem')) {
                      fazerAgendamento(beneficiario);
                    } else {
                      localStorage.clear();
                      goTo('home', jsonEmpresa.barramento);
                    }
                    //  })
                  } else {
                    // consultas.map(function (consulta) {
                    for (var i = 0; i < consultas.length; i++) {
                      var consulta = consultas[i];
                      let date = new Date();
                      // console.log(date.getHours() + ':' + date.getMinutes())

                      const dateConsulta = new Date(consulta.data + ' ' + consulta.horaFim);
                      // console.log(dateConsulta.getHours() + ':' + dateConsulta.getMinutes())

                      let dateConsultaConsideraAtraso = new Date(consulta.data + ' ' + consulta.horaFim);
                      dateConsultaConsideraAtraso.setHours(dateConsulta.getHours() + 2);
                      //console.log(dateConsultaConsideraAtraso.getHours() + ':' + dateConsultaConsideraAtraso.getMinutes())

                      if (dateConsulta.getDay() == date.getDay()) {
                        if (consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
                          console.log(consulta)
                          console.log('CONFIRMADO || AGENDADO')
                          verificarConsultas(consulta, beneficiario)
                        } else if (consulta.status === 'ESPERA') {
                          console.log(consulta)
                          console.log('ESPERA')

                          Swal.fire({
                            icon: 'info',
                            title: 'Consulta já confirmada. Por favor aguarde',
                            // html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                            showConfirmButton: false,
                            width: '800px',
                            background: '#e3f3ff',
                            timer: 7000
                          }).then(() => {
                            // window.location.href = routes.routes.frontend + 'home'; 
                            localStorage.clear();
                            goTo('home', empresa.barramento);
                          })
                        } else {
                          if (sessionStorage.getItem('totem')) {
                            Swal.fire({
                              icon: 'info',
                              title: 'Não localizamos sua consulta.',
                              html: '<h1>Deseja marcar uma consulta?​</h1>',
                              showConfirmButton: true,
                              showCancelButton: true,
                              width: '800px',
                              background: '#e3f3ff',
                              timer: 7000,
                              confirmButtonText: `Confirmar`,
                              cancelButtonText: `Cancelar`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                this.fazerAgendamento(beneficiario);
                              } else {
                                goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
                              }
                            })
                          } else {
                            if (i == (consultas.length - 1)) {
                              Swal.fire({
                                icon: 'info',
                                title: 'Não encontramos sua consulta',
                                html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                                showConfirmButton: false,
                                width: '800px',
                                background: '#e3f3ff',
                                timer: 7000
                              }).then(() => {
                                localStorage.clear();
                                goTo('home', empresa.barramento);
                              })
                            }
                          }
                        }
                      } else if (dateConsultaConsideraAtraso.getTime() > date.getTime()
                        && dateConsultaConsideraAtraso.getDay() == date.getDay()) {
                        if (consulta.status === 'ATRASADO' || consulta.status === 'CONFIRMADO' || consulta.status === 'AGENDADO') {
                          console.log(consulta)
                          console.log('ATRASADO')
                          verificarConsultas(consulta, beneficiario);
                          break;
                        }
                      } else if (i == (consultas.length - 1)) {
                        if (sessionStorage.getItem('totem')) {
                          Swal.fire({
                            icon: 'info',
                            title: 'Não localizamos sua consulta.',
                            html: '<h1>Deseja marcar uma consulta?​</h1>',
                            showConfirmButton: true,
                            showCancelButton: true,
                            width: '800px',
                            background: '#e3f3ff',
                            timer: 7000,
                            confirmButtonText: `Confirmar`,
                            cancelButtonText: `Cancelar`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              this.fazerAgendamento(beneficiario);
                            } else {
                              goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
                            }
                          })
                        } else {
                          Swal.fire({
                            icon: 'info',
                            title: 'Não encontramos sua consulta',
                            html: '<h1>Dirija-se à recepção para validarmos seu cadastro.​</h1>',
                            showConfirmButton: false,
                            width: '800px',
                            background: '#e3f3ff',
                            timer: 7000
                          })
                            .then(() => {
                              // window.location.href = routes.routes.frontend + 'home'; 
                              goTo('home', empresa.barramento);
                            })
                        }
                      }
                    }
                  }

                }, (error) => {
                  console.log(error)
                  console.log(json);
                  Swal.fire({
                    icon: 'info',
                    title: 'Desculpe',
                    html: '<h4>Houve um problema ao buscar as consultas em espera. Tente novamente, por favor</h4>',
                    showConfirmButton: false,
                    width: '800px',
                    background: '#e3f3ff',
                    timer: 4000
                  }).then(() => {
                    goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
                  })
                })
            }, (error) => {
              console.log(error)
            })

        } else if ((result != null && result.data != null && result.data[0].message === "User with this face does not exists, however profile is created. please send post request to post_url with user_id")
          || (result != null && result.data != null && result.data[0].message === "user_id has not been set, please send post request to post_url with user_id")) {

          console.log(result.data[0].message);

          localStorage.clear();
          localStorage.setItem('CadastroImagem-post_url', result.data[0].post_url);
          localStorage.setItem('image', image)
          localStorage.setItem('empresa', JSON.stringify(empresa))

          // window.location.href = routes.routes.frontend + 'naoreconhecido';
          goTo('naoreconhecido', empresa.barramento);

        } else if (result != null && result.data != null && result.data[0].message === "resolve the following errors") {

          // deu algum erro quando mandou a imagem do banco
          Swal.fire({
            icon: 'info',
            title: 'Desculpe',
            html: '<h4>Houve um problema com o reconhecimento. Tente novamente, por favor</h4>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 5000
          }).then(() => {
            goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
          })

        } else {
          console.log(json);
          localStorage.clear();
          localStorage.setItem('empresa', JSON.stringify(empresa))
          goTo('naoreconhecido', empresa.barramento);
        }
      },
        (error) => {
          console.log(error)
          console.log(json);
          Swal.fire({
            icon: 'info',
            title: 'Desculpe',
            html: '<h4>Houve um problema com o reconhecimento. Tente novamente, por favor</h4>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 5000
          }).then(() => {
            goTo('home', JSON.parse(localStorage.getItem('empresa')).barramento)
          })
        });
  }, [webcamRef, setImage]);

  function fazerAgendamento(beneficiario) {
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    localStorage.setItem('CadastroImagem-userid', beneficiario.id)
    const json = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'id_usuario': beneficiario.id,
        'loginv3': empresa.loginv3,
        'senhav3': empresa.senhav3,
        'KeyDS': empresa.keyds,
        'linkv3': empresa.linkv3,
      })
    };
    console.log(JSON.parse(json.body))
    fetch(routes.routes.backend + 'profissionais/listar', json)
      .then(res => res.json())
      .then(result => {
        if (result.profissionaisReferencia || result.profissionaisReferencia) {
          console.log(result.profissionaisReferencia)
          console.log(result.medicosGestores)
          localStorage.setItem('medicosGestores', JSON.stringify(result.medicosGestores))
          localStorage.setItem('profissionaisReferencia', JSON.stringify(result.profissionaisReferencia))
          goTo('medico-agenda', empresa.barramento)
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Não seu profissional de referência.',
            html: '<h1>Dirija-se à recepção para mais informações.​</h1>',
            showConfirmButton: false,
            width: '800px',
            background: '#e3f3ff',
            timer: 7000
          }).then(() => {
            goTo('home', empresa.barramento)
          })
        }
      })
  }

  function verificarConsultas(consulta, beneficiario) {
    const Authorization2 = 'Basic ' + Buffer.from('hmwsclient:healthm@pws.client').toString('base64');
    const idConsulta = consulta.idConsulta;
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    localStorage.clear();

    console.log(beneficiario.nome);
    // console.log(beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro');
    console.log(consulta.nomeProfissional);
    console.log(unformatDate(consulta.data));
    console.log(consulta.horaInicio + ' a ' + consulta.horaFim);
    // console.log(result.data[0].user.image);

    localStorage.setItem('nome', beneficiario.nome);
    localStorage.setItem('id_empresa', empresa.id);
    localStorage.setItem('CadastroImagem-userid', beneficiario.id);
    localStorage.setItem('documento', beneficiario.documentos !== undefined && beneficiario.documentos.size !== 0 ? beneficiario.documentos[0].documento : 'Sem cadastro');
    localStorage.setItem('medico', consulta.nomeProfissional);
    localStorage.setItem('dataConsulta', unformatDate(consulta.data));
    localStorage.setItem('horario', consulta.horaInicio + ' a ' + consulta.horaFim);
    // localStorage.setItem('user_id', beneficiario.)
    //localStorage.setItem('mensagem', 'Consulta confirmada! Por favor, aguarde ser chamado.');
    // localStorage.setItem('foto', image);
    localStorage.setItem('Authorization', Authorization2);
    localStorage.setItem('idConsulta', idConsulta);
    // window.location.href = routes.routes.frontend + 'reconhecido';
    localStorage.setItem('reconhecido', empresa);
    goTo('reconhecido', empresa.barramento);

    // }
  }

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        width={244}
        height={356}
        screenshotFormat="image/jpeg"
        style={{ left: 9999, right: 9999 }}
      />
    </>
  );
});

export default HomeComponent;
