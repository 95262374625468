import React, { Component } from "react";
import careplus from '../../assets/images/careplus.png';
import doc from '../../assets/images/doc.png';
import rioVerde from '../../assets/images/rio_verde.png';
import Swal from 'sweetalert2';
import goTo from "./goTo";
import messi from "../../assets/images/messi.png";
// import logo from '../../assets/images/logo.png';

const routes = require('../../config/router.js');

class HeaderComponent extends Component {

    render() {
        const goHome = () => {
            // window.location.href = routes.routes.frontend + 'home';
            const empresa = JSON.parse(localStorage.getItem('empresa'));
            localStorage.clear();
            goTo('home', empresa.barramento);
        }

        const goHelp = () => {

            /*
            Será imnplementado posteriormente
            const content = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            fetch(routes.routes.backend + 'conferences_create', content)
                .then(res => res.json())
                .then((result) => {
                    // console.log(result);
                    window.location.href = result.linkFull;
                })
            */
            Swal.fire({
                icon: 'info',
                html: '<h1>Por favor, dirija-se à recepção para receber ajuda.​</h1>',
                showConfirmButton: true,
                confirmButtonText: 'Voltar para o Início',
                width: '800px',
                background: '#e3f3ff',
                timer: 30000
            }).then(() => { goHome() })
        }

        const getLogo = () => {
            const search = window.location.href;
            let match = search.match(/emp=(.*)&/);
            if(!match){
                match = search.match(/emp=(.*)/)
            }
            const emp = match?.[1];
            const empresa = { barramento: emp };
            if (empresa.barramento === "teste") {
                return careplus;
            } else if (empresa.barramento === "careplus") {
                return careplus;
            } else if ((empresa.barramento === "doc")) {
                return doc;
            } else if ((empresa.barramento === "rio_verde")) {
                return rioVerde;
            } 
						// else {
            //     return messi;
            // }
        }

        if (window.location.href.includes('/home')) {
            return (
                <div id="header">
                    <div className="container-fluid">
                        <div className="navbar">
                            <div className="navigation-row">
                                <nav id="navigation">
                                    <ul className="navigation-menu nav navbar-nav navbars" id="nav">
                                        <a
                                            style={{ fontSize: 40, color: '#1c7cc4', background: 'transparent', borderColor: '#1c7cc4' }}
                                            className="btn btn-secondary btn-lg" onClick={goHome}>Início </a>
                                    </ul>
                                </nav>
                            </div>
                            <img id="logo" src={getLogo()} title="logo" alt="logo"
                                width="195" height="119"
                            />
                            <div className="navigation-row">
                                <nav id="navigation">
                                    <ul className="navigation-menu nav navbar-nav navbars" id="nav">
                                        <a
                                            className="btn btn-secondary btn-lg"
                                            style={{ fontSize: 40, color: '#1c7cc4', background: 'transparent', borderColor: '#1c7cc4' }}
                                            // href="https://videochamada.iaxsolucoes.com.br/TesteTotem"
                                            onClick={goHelp}
                                        >Precisa de ajuda?</a>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
				else if (window.location.href.includes('/video')) {
					return (
						<div></div>
					)
				}
				else {
            return (
                <div id="header">
                    <div className="container-fluid">
                        <div className="navbar">
                            <img id="logo" src={getLogo()} title="logo" alt="logo"
                                width="195" height="119" />

                            <div className="navigation-row">
                                <nav id="navigation">
                                    <ul className="navigation-menu nav navbar-nav navbars" id="nav">
                                        <a
                                            className="btn btn-secundary btn-lg"
                                            style={{ fontSize: 40, color: '#1c7cc4', background: 'transparent', borderColor: '#1c7cc4' }}
                                            // href="https://videochamada.iaxsolucoes.com.br/TesteTotem"
                                            onClick={goHelp}
                                        >Precisa de ajuda?</a>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }


    }
}

export default HeaderComponent;